import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_ERROR,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_ERROR,
    VERIFY_USER,
    VERIFY_USER_SUCCESS,
    VERIFY_USER_ERROR,
    LOGOUT_USER,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR
} from '../actions';

const INIT_STATE = {
    useremail: localStorage.getItem('mosaic-useremail'),
    username: localStorage.getItem('mosaic-username'),
    jwtToken: localStorage.getItem('mosaic-token'),
    apiKey: localStorage.getItem('mosaic-api-key'),
    userorg:  localStorage.getItem('mosaic-userorg'),
    orgusage:  localStorage.getItem('mosaic-orgusage'),
    userusage:  localStorage.getItem('mosaic-userusage'),
    orglimit:  localStorage.getItem('mosaic-orglimit'),
    forgotUserMail: '',
    newPassword: '',
    verificationCode: '',
    loading: false,
    error: ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER:
        case REGISTER_USER:
        case VERIFY_USER:
            return { ...state, loading: true, error: '' };
        case LOGIN_USER_SUCCESS:
// console.log('REDUCER LOGIN_USER_SUCCESS',action.payload)
            return { ...state, loading: false,
              username: action.payload.CognitoUser.username.split('@')[0],
              useremail: action.payload.CognitoUser.username,
              userorg: action.payload.CognitoUser.username.split('@')[1].replaceAll('.','-'),
              jwtToken: action.payload.CognitoUser.signInUserSession.accessToken.jwtToken,
              apiKey: action.payload.ApiKey,
              orgusage:  action.payload.OrgUsage.usage_org,
              userusage:  action.payload.OrgUsage.usage,
              orglimit:  action.payload.OrgUsage.limit,
              error: '' };
        case LOGIN_USER_ERROR:
        case REGISTER_USER_ERROR:
            return { ...state, loading: false,
              username: '',
              useremail: '',
              userorg: '',
              orgusage: '',
              userusage: '',
              orglimit: '',
              jwtToken: '',
              error: action.payload.message };
        case REGISTER_USER_SUCCESS:
            return { ...state, loading: false,
              username: action.payload.username.split('@')[0],
              useremail: action.payload.username,
              userorg: action.payload.username.split('@')[1].replaceAll('.','-'),
              apiKey: action.payload.ApiKey,
              orgusage:  action.payload.OrgUsage.usage_org,
              userusage:  action.payload.OrgUsage.usage,
              orglimit:  action.payload.OrgUsage.limit,
              jwtToken: '',
              error: '' };
        case FORGOT_PASSWORD:
            return { ...state, loading: true, error: '' };
        case FORGOT_PASSWORD_SUCCESS:
            return { ...state, loading: false, forgotUserMail: action.payload, error: '' };
        case FORGOT_PASSWORD_ERROR:
            return { ...state, loading: false, forgotUserMail: '', error: action.payload.message };
        case RESET_PASSWORD:
            return { ...state, loading: true, error: '' };

        case RESET_PASSWORD_SUCCESS:
        case VERIFY_USER_SUCCESS:
            return { ...state, loading: false, newPassword: action.payload, verificationCode: '', error: '' };

        case RESET_PASSWORD_ERROR:
        case VERIFY_USER_ERROR:
            return { ...state, loading: false, newPassword: '', verificationCode: '', error: action.payload.message };
        case LOGOUT_USER:
            return { ...state,
              username: null,
              useremail: null,
              userorg: '',
              orgusage: '',
              userusage: '',
              orglimit: '',
              jwtToken: null,
              apiKey: 'None',
              error: '' };
        default: return { ...state };
    }
}
