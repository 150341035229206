import { all, call, fork, put, takeEvery  } from "redux-saga/effects";
import { API } from "aws-amplify";
import awsConfig from '../../helpers/Aws.js'
import { superEncodeURI } from '../../helpers/Utils.js'

import {
    UPLOAD_GET_LIST,
    UPLOAD_SUBMIT,
    UPLOAD_DELETE,
    UPLOAD_SHARE_USER,
    UPLOAD_SHARE_ORG,
} from '../actions';

import {
  getUploadListSuccess,
  getUploadListError,
  submitUploadSuccess,
  submitUploadError,
  deleteUploadSuccess,
  deleteUploadError,
  shareUploadUserSuccess,
  shareUploadUserError,
  shareUploadOrgSuccess,
  shareUploadOrgError,
} from "./actions";

/*
 * Get list of uploads
 */
const getUploadListRequest = async () => {
  return await new Promise((success, fail) => {
    API.get(awsConfig.apiGateway.NAME, "/datasets")
      .then(response => {
          return response;
        }).then(data => {
          console.log('apiUploadSummary done',data);
          success(data);
      }).catch(error => {
          fail(new Error(error)); //in case of timeout on token
      });
  })
    .then(response => response)
    .catch(error =>  error);
};

function* getUploadListItems() {
  try {
    const response = yield call(getUploadListRequest);
    yield put(getUploadListSuccess(response));
  } catch (error) {
    yield put(getUploadListError(error));
  }
}

export function* watchGetUploadList() {
  yield takeEvery(UPLOAD_GET_LIST, getUploadListItems);
}

/*
 * Submit upload
 */
const submitUploadRequest = async (title, s3Key) => {
  return await new Promise((success, fail) => {
    API.post(awsConfig.apiGateway.NAME, "/datasets", {
       body: { title: title,
               s3Key: s3Key
             }
       }).then(data => {
          success(data);
        }).catch(error => {
            fail(new Error(error)); //in case of timeout on token
        });
    })
    .then(response => response)
    .catch(error => error);
};

function* submitUpload({ payload }) {
  const title = payload.title;
  const s3Key = payload.s3Key;
  try {
    const response = yield call(submitUploadRequest,title,s3Key);
    yield put(submitUploadSuccess(response));
  } catch (error) {
    yield put(submitUploadError(error));
  }
}

export function* watchSubmitUpload() {
  yield takeEvery(UPLOAD_SUBMIT, submitUpload);
}

/*
 * Delete upload
 */
const deleteUploadRequest = async (id) => {
  return await new Promise((success, fail) => {
    API.post(awsConfig.apiGateway.NAME, "/trade-item/metadata", {
        body: { id: id,
                action: "delete"
              }
      }).then(response => {
          return response;
        }).then(data => {
          success(id);
        }).catch(error => {
            fail(new Error(error)); //in case of timeout on token
        });
    })
    .then(response => response)
    .catch(error => error);
};

function* deleteUpload({ payload }) {
  const id = payload;
  try {
    const response = yield call(deleteUploadRequest,id);
    yield put(deleteUploadSuccess(response));
  } catch (error) {
    yield put(deleteUploadError(error));
  }
}

export function* watchDeleteUpload() {
  yield takeEvery(UPLOAD_DELETE, deleteUpload);
}

/*
 * Share upload with another User, e.g. emele@it2solutions.com
 */
  const shareUploadUserRequest = async (id, title, recipient) => {
    const datasetId = superEncodeURI(id);
    const payload =  {
      title:  superEncodeURI(title),
      recipient: recipient
    }
  // console.log('shareUploadUserRequest',payload)
  // console.log('endpoint',awsConfig.apiGateway.NAME, `/dataset/${datasetId}/share-user`);
   return await new Promise((success, fail) => {
     API.post(awsConfig.apiGateway.NAME, `/dataset/${datasetId}/share-user`, {
        body: payload
        }).then(data => {
           success(data);
         }).catch(error => {
             fail(new Error(error)); //in case of timeout on token
         });
     })
     .then(response => response)
     .catch(error => error );
 };

 function* shareUploadUser({ payload }) {
   const {
      id,
      title,
      recipient
    } = payload;

    try {
     const response = yield call(shareUploadUserRequest, id, title, recipient);
     yield put(shareUploadUserSuccess(response));
   } catch (error) {
     yield put(shareUploadUserError(error));
   }
 }

 export function* watchShareUploadUser() {
   yield takeEvery(UPLOAD_SHARE_USER, shareUploadUser);
 }

 /*
  * Share upload with the ORG, emele@it2solutions.com
  */
  const shareUploadOrgRequest = async (id, title) => {
    const datasetId = superEncodeURI(id);
    const payload =  {
      title:  superEncodeURI(title)
    }
    return await new Promise((success, fail) => {
      API.post(awsConfig.apiGateway.NAME, `/dataset/${datasetId}/share-org`, {
         body: payload
         }).then(data => {
            success(data);
          }).catch(error => {
              fail(new Error(error)); //in case of timeout on token
          });
      })
      .then(response => response)
      .catch(error => error );
  };

  function* shareUploadOrg({ payload }) {
    const {
       id,
       title
     } = payload;

     try {
      const response = yield call(shareUploadOrgRequest, id, title);
      yield put(shareUploadOrgSuccess(response));
    } catch (error) {
      yield put(shareUploadOrgError(error));
    }
  }

  export function* watchShareUploadOrg() {
    yield takeEvery(UPLOAD_SHARE_ORG, shareUploadOrg);
  }

export default function* rootSaga() {
  yield all([
      fork(watchGetUploadList),
      fork(watchSubmitUpload),
      fork(watchDeleteUpload),
      fork(watchShareUploadUser),
      fork(watchShareUploadOrg),
      ]);
}
