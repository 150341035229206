import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { API } from "aws-amplify";
// import awsConfig from '../../helpers/Aws.js'
import axios from 'axios';

import {
    VENN_GET_LIST,
} from '../actions';

import {
  getVennListSuccess,
  getVennListError,
} from "./actions";

/*
 * Get list of items to align - trade item get shouldnt be used any more - it should be upload metadata get
 */
const getVennListRequest = async (s3_presignedurl_list_left, s3_presignedurl_list_right) => {
// console.log('getVennListRequest',s3_presignedurl_list_left, s3_presignedurl_list_right)
  return await new Promise((success, fail) => {
    let ret = {
      trade_items_left: [],
      trade_items_right: []
    }
    //LEFT SIDE
    //now go through the list and grab the nk data
    let promises = []
    s3_presignedurl_list_left.forEach( s3url => {
      promises.push(axios.get(s3url));
    });

    axios.all(promises)
    .then(responseArr => {
      responseArr.forEach( res => {
        ret.trade_items_left = ret.trade_items_left.concat(res.data);
      });

      promises = []
      s3_presignedurl_list_right.forEach( s3url => {
        promises.push(axios.get(s3url));
      });
      axios.all(promises)
      .then(responseArr => {
        responseArr.forEach( res => {
          ret.trade_items_right = ret.trade_items_right.concat(res.data);
        });
        success(ret);
      });
    });
  });
};

/* is this a get-list for the left side or the right side? */
function* getVennListItems({ payload }) {
  const { s3_presignedurl_list_left, s3_presignedurl_list_right } = payload;
  try {
    const response = yield call(getVennListRequest,s3_presignedurl_list_left,s3_presignedurl_list_right);
    yield put(getVennListSuccess(response));
  } catch (error) {
    yield put(getVennListError(error));
  }
}

export function* watchGetVennList() {
  yield takeEvery(VENN_GET_LIST, getVennListItems);
}


export default function* rootSaga() {
  yield all([
    fork(watchGetVennList),
  ]);
}
