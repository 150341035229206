import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { API } from "aws-amplify";
import awsConfig from '../../helpers/Aws.js'
import { superEncodeURI } from '../../helpers/Utils.js'

import {
  SEARCH_LOCATION_GET_LIST,
  SEARCH_LOCATION_GET_LIST_CANDIDATES
} from "../actions";

import {
  getSearchLocationListSuccess,
  getSearchLocationListError,
  getSearchLocationListCandidatesSuccess,
  getSearchLocationListCandidatesError,
} from "./actions";


/*
* search - no user values
*/
const getSearchLocationListCandidatesRequest = async (search) => {
  let payload = {
    "search": superEncodeURI(search)
  }
  return await new Promise((success, fail) => {
console.log('getSearchLocationListCandidatesRequest',payload)
    API.get(awsConfig.apiGateway.NAME, `/location/${payload.search}`)
      .then(response => {
          return response;
        }).then(data => {
          console.log('apiGetLocation done',data);
          success(data)
        }).catch(error => {
            fail(new Error(error)); //in case of timeout on token
        });
  })
    .then(response => response)
    .catch(error => error);
};

function* getSearchLocationListCandidatesItems({ payload }) {
  const searchKeyword = payload;
  try {
    if (searchKeyword.length > 0) {
      const response = yield call(getSearchLocationListCandidatesRequest,searchKeyword);
      yield put(getSearchLocationListCandidatesSuccess(response));
    } else yield put(getSearchLocationListCandidatesSuccess([]));
  } catch (error) {
    yield put(getSearchLocationListCandidatesError(error));
  }
}

export function* watchGetLocationListCandidates() {
  yield takeEvery(SEARCH_LOCATION_GET_LIST_CANDIDATES, getSearchLocationListCandidatesItems);
}



/*
* search
*/
const getSearchLocationListRequest = async (search) => {
  let payload = {
    "search": superEncodeURI(search)
  }
  return await new Promise((success, fail) => {
    API.get(awsConfig.apiGateway.NAME, `/datasets/location/${payload.search}`)
      .then(response => {
          return response;
        }).then(data => {
          // console.log('getSearchLocationListRequest done',data);
          success(data)
        }).catch(error => {
            fail(new Error(error)); //in case of timeout on token
        });
  })
    .then(response => response)
    .catch(error => error);
};

function* getSearchLocationListItems({ payload }) {
  const { searchKeyword } = payload;

  try {
    const response = yield call(getSearchLocationListRequest,searchKeyword);
    yield put(getSearchLocationListSuccess(response));
  } catch (error) {
    yield put(getSearchLocationListError(error));
  }
}

export function* watchGetLocationList() {
  yield takeEvery(SEARCH_LOCATION_GET_LIST, getSearchLocationListItems);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetLocationList),
    fork(watchGetLocationListCandidates),

  ]);
}
