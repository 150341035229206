import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { API } from "aws-amplify";
import awsConfig from '../../helpers/Aws.js'
import { superEncodeURI } from '../../helpers/Utils.js'

import {
  SEARCH_GET_LIST,
  SEARCH_GET_LIST_CANDIDATES,
  SEARCH_GET_PRODUCT_METADATA,
} from "../actions";

import {
  getSearchListSuccess,
  getSearchListError,
  getSearchListCandidatesSuccess,
  getSearchListCandidatesError,
  getProductMetadataSuccess,
  getProductMetadataError,
} from "./actions";

/*
* search - no user values
*/
const getSearchListCandidatesRequest = async (search) => {
  let payload = {
    "search": superEncodeURI(search)
  }
  return await new Promise((success, fail) => {
console.log('getSearchListCandidatesRequest',payload)
    API.get(awsConfig.apiGateway.NAME, `/product/${payload.search}`)
      .then(response => {
          return response;
        }).then(data => {
          console.log('apiGetProduct done',data);
          success(data)
        }).catch(error => {
            fail(new Error(error)); //in case of timeout on token
        });
  })
    .then(response => response)
    .catch(error => error);
};

function* getSearchListCandidatesItems({ payload }) {
  const searchKeyword = payload;
  try {
    if (searchKeyword.length > 0) {
      const response = yield call(getSearchListCandidatesRequest,searchKeyword);
      yield put(getSearchListCandidatesSuccess(response));
    } else yield put(getSearchListCandidatesSuccess([]));
  } catch (error) {
    yield put(getSearchListCandidatesError(error));
  }
}

export function* watchGetListCandidates() {
  yield takeEvery(SEARCH_GET_LIST_CANDIDATES, getSearchListCandidatesItems);
}

/*
* search
*/
const getSearchListRequest = async (search) => {
  let payload = {
    "search": superEncodeURI(search)
  }
  return await new Promise((success, fail) => {
    API.get(awsConfig.apiGateway.NAME, `/datasets/${payload.search}`)
      .then(response => {
          return response;
        }).then(data => {
          console.log('apiGetProduct done',data);
          success(data)
        }).catch(error => {
            fail(new Error(error)); //in case of timeout on token
        });
  })
    .then(response => response)
    .catch(error => error);
};

function* getSearchListItems({ payload }) {
  const searchKeyword = payload;
  try {
    const response = yield call(getSearchListRequest,searchKeyword);
    yield put(getSearchListSuccess(response));
  } catch (error) {
    yield put(getSearchListError(error));
  }
}

export function* watchGetList() {
  yield takeEvery(SEARCH_GET_LIST, getSearchListItems);
}


/*
* product metadata
*/
const getProductMetadataRequest = async (search) => {
  return await new Promise((success, fail) => {
    API.get(awsConfig.apiGateway.NAME, "/product/metadata")
    .then(response => {
        return response;
      }).then(data => {
        success(data);
      }).catch(error => {
          fail(new Error(error)); //in case of timeout on token
      });
  })
    .then(response => response)
    .catch(error => error);
  };

function* getProductMetadata() {
  try {
    const response = yield call(getProductMetadataRequest);
    yield put(getProductMetadataSuccess(response));
  } catch (error) {
    yield put(getProductMetadataError(error));
  }
}

export function* watchGetProductMetadata() {
  yield takeEvery(SEARCH_GET_PRODUCT_METADATA, getProductMetadata);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetListCandidates),
    fork(watchGetProductMetadata),
  ]);
}
