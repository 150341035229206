import {
    VENN_GET_LIST,
    VENN_GET_LIST_SUCCESS,
    VENN_GET_LIST_ERROR,
    VENN_GET_LIST_WITH_FILTER,
    VENN_CHANGE_SET_OPERATION,
    VENN_UPDATE_ALIGNMENT,
} from '../actions';



export const getVennListWithFilter = (items) => ({
    type: VENN_GET_LIST_WITH_FILTER,
    payload: items
});

export const changeSetOperation = (operation) => ({
    type: VENN_CHANGE_SET_OPERATION,
    payload: operation
});

export const updateVennAlignment = (id,nk,matchEntry) => ({
  type: VENN_UPDATE_ALIGNMENT,
  payload: { id, nk, matchEntry }
});

/*
 * Get list of items for 1 side
 */

export const getVennList = (s3_presignedurl_list_left,s3_presignedurl_list_right) => ({
    type: VENN_GET_LIST,
    payload: {s3_presignedurl_list_left,s3_presignedurl_list_right}
});

export const getVennListSuccess = (items) => ({
    type: VENN_GET_LIST_SUCCESS,
    payload: {items}
});

export const getVennListError = (error) => ({
    type: VENN_GET_LIST_ERROR,
    payload: error
});
