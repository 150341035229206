import {
  ALIGN_COUNTRY_GET_LIST,
  ALIGN_COUNTRY_GET_LIST_SUCCESS,
  ALIGN_COUNTRY_GET_LIST_ERROR,
  ALIGN_COUNTRY_GET_LIST_WITH_FILTER,
} from '../actions';

import {createCountryMasterOrientation} from '../../helpers/Utils';

const INIT_STATE = {
	loading: true,

	allItems: null,
  items: null,
  selectedItem: null,
  masterOrientation: null,
  allMasterOrientation: null,

};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

    case ALIGN_COUNTRY_GET_LIST:
    // console.log('REDUCER ALIGN_GET_LIST',action.payload)
		return { ...state, items: null, allItems: null, allMasterOrientation: null, masterOrientation: null, loading: false  };

		case ALIGN_COUNTRY_GET_LIST_SUCCESS:
    // console.log('REDUCER ALIGN_COUNTRY_GET_LIST_SUCCESS',action.payload)
    const masterOrientation = createCountryMasterOrientation(action.payload.items);
		return { ...state, loading: true,
												allItems: action.payload.items,
                        items: action.payload.items,
                        allMasterOrientation:  masterOrientation,
                        masterOrientation:  masterOrientation,
						};

		case ALIGN_COUNTRY_GET_LIST_ERROR:
    // console.log('REDUCER ALIGN_COUNTRY_GET_LIST_ERROR',action.payload)
			return { ...state,  loading: false, error: action.payload };

    case ALIGN_COUNTRY_GET_LIST_WITH_FILTER:
 // console.log('REDUCER ALIGN_COUNTRY_GET_LIST_WITH_FILTER',action.payload)
      //an array of 1, for now
      if ( action.payload.length > 0 ) {
        let itemsFiltered = state.allItems.filter( d => { return action.payload[0] === d.matched_entry.l1_code ? d : null; }  );
        return { ...state,
                    items: itemsFiltered,
                    masterOrientation:  createCountryMasterOrientation(itemsFiltered)
                };
      }
      else {
        return { ...state, items: state.allItems, masterOrientation: state.allMasterOrientation};
      }

		default: return { ...state };
	}
}
