import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import datalakeSearchSagas from './datalakeSearch/saga';
import datalakeSearchLocationSagas from './datalakeSearchLocation/saga';
import datalakeUploadSagas from './datalakeUpload/saga';
import datalakeAlignSagas from './datalakeAlign/saga';
import datalakeAlignCountrySagas from './datalakeAlignCountry/saga';
import datalakeAlignLocationSagas from './datalakeAlignLocation/saga';
import datalakeVennSagas from './datalakeVenn/saga';
import supplyplanSagas from './supplyplan/saga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    datalakeSearchSagas(),
    datalakeSearchLocationSagas(),
    datalakeUploadSagas(),
    datalakeAlignSagas(),
    datalakeAlignCountrySagas(),
    datalakeAlignLocationSagas(),
    datalakeVennSagas(),
    supplyplanSagas(),
  ]);
}
