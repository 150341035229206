import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { API } from "aws-amplify";
import awsConfig from '../../helpers/Aws.js'
import { superEncodeURI } from '../../helpers/Utils.js'

import axios from 'axios';

import {
    ALIGN_GET_LIST,
    ALIGN_GET_CANDIDATE_LIST,
    ALIGN_CHANGE,
} from '../actions';

import {
  getAlignListSuccess,
  getAlignListError,
  getCandidateListSuccess,
  getCandidateListError,
  changeAlignmentSuccess,
  changeAlignmentError,
} from "./actions";

/*
 * Get list of items to align
 */
const getAlignListRequest = async (selectedUploadItem,s3_presignedurl_list) => {
  // console.log('getAlignListRequest',getAlignListRequest)
  // var time = new Date();
  // console. log('getAlignListRequest start',time.getHours() + ":" + time)
  return await new Promise((success, fail) => {
    let ret = {
      selectedUploadItem: selectedUploadItem,
      trade_items: []
    }
    //now go through the list and grab the nk data
    let promises = []
    s3_presignedurl_list.forEach( s3url => {
      promises.push(axios.get(s3url));
    });

    axios.all(promises)
    .then(responseArr => {
      //this will be executed only when all requests are complete
      responseArr.forEach( res => {
        ret.trade_items = ret.trade_items.concat(res.data);
      });
      // console. log('getAlignListRequest finish',time.getHours() + ":" + time)
      success(ret);
    });
  });
}


function* getAlignListItems({ payload }) {
  const { selectedUploadItem, s3_presignedurl_list } = payload;
  try {
    const response = yield call(getAlignListRequest,selectedUploadItem, s3_presignedurl_list);
    yield put(getAlignListSuccess(response));
  } catch (error) {
    yield put(getAlignListError(error));
  }
}

export function* watchGetAlignList() {
  yield takeEvery(ALIGN_GET_LIST, getAlignListItems);
}

/*
 * Get classification candidates
 */
const getCandidateListRequest = async (id,nk) => {
  let payload = {
    "id": superEncodeURI(id),
    "nk":superEncodeURI(nk)
  }
  console.log('getCandidateListRequest',payload)
  return await new Promise((success, fail) => {
    API.get(awsConfig.apiGateway.NAME,`/dataset/${payload.id}/product/${payload.nk}`)
      .then(response => {
          return response;
        }).then(data => {
          success(data);
        }).catch(error => {
            fail(new Error(error)); //in case of timeout on token
        });
  })
    .then(response => response)
    .catch(error => error);
};


function* getCandidateListItems({ payload }) {
// console.log('SAGA getCandidateListItems',payload)
  const { id,nk } = payload;
  try {
    const response = yield call(getCandidateListRequest,id,nk);
    yield put(getCandidateListSuccess(response));
  } catch (error) {
    yield put(getCandidateListError(error));
  }
}

export function* watchGetCandidateList() {
  yield takeEvery(ALIGN_GET_CANDIDATE_LIST, getCandidateListItems);
}

/*
 * change alignment
 */
const changeAlignmentRequest = async (tradeItem, matchEntry) => {
  let matched_product_entry = matchEntry.matched_product_entry;
  matched_product_entry.pack_size_str = tradeItem.packSizeStr;
  const packSizeAmtParts = tradeItem.packSizeStr.split('Pack size ');
  if ( packSizeAmtParts.length > 1 )
    matched_product_entry.pack_size_amt = packSizeAmtParts[1];
  else
    matched_product_entry.pack_size_amt = 1;

  let payload = {
    id: superEncodeURI(tradeItem.id),
    nk: superEncodeURI(tradeItem.nk),
    matched_product_entry: matched_product_entry,
    matched_product_name: matchEntry.matched_product_name
  }
  console.log('trade item, matchEntry, and payload',tradeItem,matchEntry,payload)

  console.log(awsConfig.apiGateway.NAME,`/dataset/${payload.id}/product/${payload.nk}`)
  return await new Promise((success, fail) => {
    API.post(awsConfig.apiGateway.NAME,`/dataset/${payload.id}/product/${payload.nk}`, {
      body: payload
      }).then(data => {
          success(data);
        }).catch(error => {
            fail(new Error(error)); //in case of timeout on token
        });
  })
  .then(response => response)
  .catch(error => error);
};

function* changeAlignment({ payload }) {
console.log('changeAlignment',payload)
  const { tradeItem, matchEntry} = payload;
  try {
    const response = yield call(changeAlignmentRequest,tradeItem,matchEntry);
    yield put(changeAlignmentSuccess(response));
  } catch (error) {
    yield put(changeAlignmentError(error));
  }
}

export function* watchChangeAlignment() {
  yield takeEvery(ALIGN_CHANGE, changeAlignment);
}


export default function* rootSaga() {
  yield all([
    fork(watchGetAlignList),
    fork(watchGetCandidateList),
    fork(watchChangeAlignment),
  ]);
}
