import {
  ALIGN_LOCATION_GET_LIST,
  ALIGN_LOCATION_GET_LIST_SUCCESS,
  ALIGN_LOCATION_GET_LIST_ERROR,
  ALIGN_LOCATION_GET_LIST_WITH_FILTER,
  ALIGN_LOCATION_GET_CANDIDATE_LIST,
  ALIGN_LOCATION_GET_CANDIDATE_LIST_SUCCESS,
  ALIGN_LOCATION_GET_CANDIDATE_LIST_ERROR,
  ALIGN_LOCATION_CHANGE,
  ALIGN_LOCATION_CHANGE_SUCCESS,
  ALIGN_LOCATION_CHANGE_ERROR,
} from '../actions';


/*
 * Get list of items to be aligned
 */
export const getAlignLocationList = (id) => ({
    type: ALIGN_LOCATION_GET_LIST,
    payload: id
});

export const getAlignLocationListSuccess = (items) => ({
    type: ALIGN_LOCATION_GET_LIST_SUCCESS,
    payload: items
});

export const getAlignLocationListError = (error) => ({
    type: ALIGN_LOCATION_GET_LIST_ERROR,
    payload: error
});

/*
 * Get classification candidates
 */
export const getLocationCandidateList = (id,record_type,best_nk,record_classificationPath) => ({
    type: ALIGN_LOCATION_GET_CANDIDATE_LIST,
    payload: { id,record_type,best_nk,record_classificationPath }
});

export const getLocationCandidateListSuccess = (items) => ({
    type: ALIGN_LOCATION_GET_CANDIDATE_LIST_SUCCESS,
    payload: items
});

export const getLocationCandidateListError = (error) => ({
    type: ALIGN_LOCATION_GET_CANDIDATE_LIST_ERROR,
    payload: error
});


/*
 * Change alignment
 */
 export const changeLocationAlignment = (locationItem, matchEntry) => ({
     type: ALIGN_LOCATION_CHANGE,
     payload: {locationItem, matchEntry }
 });

 export const changeLocationAlignmentSuccess = (items) => ({
     type: ALIGN_LOCATION_CHANGE_SUCCESS,
     payload: items
 });

 export const changeLocationAlignmentError = (error) => ({
     type: ALIGN_LOCATION_CHANGE_ERROR,
     payload: error
 });
