import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { API } from "aws-amplify";
import awsConfig from '../../helpers/Aws.js'
import axios from 'axios';
import { superEncodeURI } from '../../helpers/Utils.js'

import {
  ALIGN_LOCATION_GET_LIST,
  ALIGN_LOCATION_GET_CANDIDATE_LIST,
  ALIGN_LOCATION_CHANGE,
} from '../actions';

import {
  getAlignLocationListSuccess,
  getAlignLocationListError,
  getLocationCandidateListSuccess,
  getLocationCandidateListError,
  changeLocationAlignmentSuccess,
  changeLocationAlignmentError,
} from "./actions";

/*
 * Get list of items to align
 */
const getAlignLocationListRequest = async (id) => {

    return await new Promise((success, fail) => {
      API.get(awsConfig.apiGateway.NAME, "/datasets")
      .then(response => {
          return response;
        }).then(data => {
          // console.log('getAlignLocationListRequest apiGetUploadMeta done ',data);
          const completedUploads = data.uploads.filter( u => u.location.processing_status === 'Complete' && u.location.entries_classified > 0 )
          let uploadList = [];
          let ret = {
            items: []
          }
          //find my id
          if ( id !== undefined && id !== "" && id !== "ALL") {
            const thisUpload = data.uploads.find( u => u.id === id );
             uploadList.push(thisUpload.location.nk_s3_presignedurl);
          }
          //blank means first one which is complete
          else if ( id === "ALL" ) {
            completedUploads.forEach( u => {
              uploadList.push(u.location.nk_s3_presignedurl)
            });
          }
          else {
            uploadList.push(completedUploads[0].location.nk_s3_presignedurl)
          }

          //now go through the list and grab the nk data
          let promises = []
          uploadList.forEach( s3url => {
            promises.push(axios.get(s3url));
          });

          axios.all(promises)
          .then(responseArr => {
            //this will be executed only when all requests are complete
            responseArr.forEach( res => {
              ret.items = ret.items.concat(res.data);
            });
            success(ret);
          });
        }).catch(error => {
            fail(new Error(error)); //in case of timeout on token
        });
      })
        .then(response => response)
        .catch(error => {
            console.log('caught error');
            return error
        });
    };

function* getAlignLocationListItems({ payload }) {
  const id = payload;
  try {
    const response = yield call(getAlignLocationListRequest,id);
    yield put(getAlignLocationListSuccess(response));
  } catch (error) {
    yield put(getAlignLocationListError(error));
  }
}

export function* watchGetAlignLocationList() {
  yield takeEvery(ALIGN_LOCATION_GET_LIST, getAlignLocationListItems);
}


/*
 * Get classification candidates
 */
function* getLocationCandidateListItems({ payload }) {
// console.log('SAGA getCandidateListItems',payload)
  const {id,record_type,best_nk,record_classificationPath } = payload;
  yield put(getLocationCandidateListSuccess(payload));
}

export function* watchGetLocationCandidateList() {
  yield takeEvery(ALIGN_LOCATION_GET_CANDIDATE_LIST, getLocationCandidateListItems);
}


/*
 * change Location alignment
 */
const changeLocationAlignmentRequest = async (locationItem, matchEntry) => {
  let matched_entry = matchEntry.matched_entry;

  let payload = {
    id: superEncodeURI(locationItem.id),
    record_type: superEncodeURI(locationItem.record_type),
    matched_entry: matched_entry,
    matched_name: matchEntry.matched_name
  }
  console.log('location item, matchEntry, and payload',locationItem,matchEntry,payload)

  console.log(awsConfig.apiGateway.NAME,`/dataset/${payload.id}/location/${payload.record_type}`)
  return await new Promise((success, fail) => {
    API.post(awsConfig.apiGateway.NAME,`/dataset/${payload.id}/location/${payload.record_type}`, {
      body: payload
      }).then(data => {
          success(data);
        }).catch(error => {
            fail(new Error(error)); //in case of timeout on token
        });
  })
  .then(response => response)
  .catch(error => error);
};

function* changeLocationAlignment({ payload }) {
console.log('changeLocationAlignment',payload)
  const { locationItem, matchEntry} = payload;
  try {
    const response = yield call(changeLocationAlignmentRequest,locationItem,matchEntry);
    yield put(changeLocationAlignmentSuccess(response));
  } catch (error) {
    yield put(changeLocationAlignmentError(error));
  }
}

export function* watchChangeLocationAlignment() {
  yield takeEvery(ALIGN_LOCATION_CHANGE, changeLocationAlignment);
}



export default function* rootSaga() {
  yield all([
    fork(watchGetAlignLocationList),
    fork(watchGetLocationCandidateList),
    fork(watchChangeLocationAlignment),
  ]);
}
