import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { API } from "aws-amplify";
import awsConfig from '../../helpers/Aws.js'
import axios from 'axios';

import {
    ALIGN_COUNTRY_GET_LIST,
} from '../actions';

import {
  getAlignCountryListSuccess,
  getAlignCountryListError,
} from "./actions";

/*
 * Get list of items to align
 */
const getAlignCountryListRequest = async (id) => {

    return await new Promise((success, fail) => {
      API.get(awsConfig.apiGateway.NAME, "/datasets")
      .then(response => {
          return response;
        }).then(data => {
          console.log('apiGetUploadMeta done ',data);
          const completedUploads = data.uploads.filter( u => u.country.processing_status === 'Complete' && u.country.entries_classified > 0 )
          let uploadList = [];
          let ret = {
            items: []
          }
          //find my id
          if ( id !== undefined && id !== "" && id !== "ALL") {
            const thisUpload = data.uploads.find( u => u.id === id );
             uploadList.push(thisUpload.country.nk_s3_presignedurl);
          }
          //blank means first one which is complete
          else if ( id === "ALL" ) {
            completedUploads.forEach( u => {
              uploadList.push(u.country.nk_s3_presignedurl)
            });
          }
          else {
            uploadList.push(completedUploads[0].country.nk_s3_presignedurl)
          }

          //now go through the list and grab the nk data
          let promises = []
          uploadList.forEach( s3url => {
            promises.push(axios.get(s3url));
          });

          axios.all(promises)
          .then(responseArr => {
            //this will be executed only when all requests are complete
            responseArr.forEach( res => {
              ret.items = ret.items.concat(res.data);
            });
            success(ret);
          });
        }).catch(error => {
            fail(new Error(error)); //in case of timeout on token
        });
      })
        .then(response => response)
        .catch(error => {
            console.log('caught error');
            return error
        });
    };

function* getAlignCountryListItems({ payload }) {
  const id = payload;
  try {
    const response = yield call(getAlignCountryListRequest,id);
    yield put(getAlignCountryListSuccess(response));
  } catch (error) {
    yield put(getAlignCountryListError(error));
  }
}

export function* watchGetAlignCountryList() {
  yield takeEvery(ALIGN_COUNTRY_GET_LIST, getAlignCountryListItems);
}



export default function* rootSaga() {
  yield all([
    fork(watchGetAlignCountryList),
  ]);
}
