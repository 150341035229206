import {
    UPLOAD_GET_LIST,
    UPLOAD_GET_LIST_SUCCESS,
    UPLOAD_GET_LIST_ERROR,
    UPLOAD_CHANGE_SELECTION,
    UPLOAD_CHANGE_SELECTION_2,
    UPLOAD_SUBMIT,
    UPLOAD_SUBMIT_SUCCESS,
    UPLOAD_SUBMIT_ERROR,
    UPLOAD_DELETE,
    UPLOAD_DELETE_SUCCESS,
    UPLOAD_DELETE_ERROR,
    UPLOAD_SHARE_USER,
    UPLOAD_SHARE_USER_SUCCESS,
    UPLOAD_SHARE_USER_ERROR,
    UPLOAD_SHARE_ORG,
    UPLOAD_SHARE_ORG_SUCCESS,
    UPLOAD_SHARE_ORG_ERROR,
} from '../actions';


export const uploadChangeSelection = (selectedItem) => ({
    type: UPLOAD_CHANGE_SELECTION,
    payload: selectedItem
});


export const uploadChangeSelection_2 = (selectedItem) => ({
    type: UPLOAD_CHANGE_SELECTION_2,
    payload: selectedItem
});

/*
 * Get list of uploads
 */
export const getUploadList = (selectedItem) => ({
    type: UPLOAD_GET_LIST,
    payload: selectedItem
});

export const getUploadListSuccess = (items) => ({
    type: UPLOAD_GET_LIST_SUCCESS,
    payload: items
});

export const getUploadListError = (error) => ({
    type: UPLOAD_GET_LIST_ERROR,
    payload: error
});

/*
 * Submit upload
 */
export const submitUpload = (title, s3Key) => ({
    type: UPLOAD_SUBMIT,
    payload: {title, s3Key}
});

export const submitUploadSuccess = (items) => ({
    type: UPLOAD_SUBMIT_SUCCESS,
    payload: items
});

export const submitUploadError = (error) => ({
    type: UPLOAD_SUBMIT_ERROR,
    payload: error
});

/*
 * Delete upload
 */
export const deleteUpload = (id) => ({
    type: UPLOAD_DELETE,
    payload: id
});

export const deleteUploadSuccess = (id) => ({
    type: UPLOAD_DELETE_SUCCESS,
    payload: id
});

export const deleteUploadError = (error) => ({
    type: UPLOAD_DELETE_ERROR,
    payload: error
});

/*
 * Share upload with a User
 */
 export const shareUploadUser = (id, title, recipient) => ({
     type: UPLOAD_SHARE_USER,
     payload: {id, title, recipient}
 });

 export const shareUploadUserSuccess = (id) => ({
     type: UPLOAD_SHARE_USER_SUCCESS,
     payload: id
 });

 export const shareUploadUserError = (error) => ({
     type: UPLOAD_SHARE_USER_ERROR,
     payload: error
 });

 /*
  * Share upload with Org
  */
  export const shareUploadOrg = (id, title) => ({
      type: UPLOAD_SHARE_ORG,
      payload: {id, title }
  });

  export const shareUploadOrgSuccess = (id) => ({
      type: UPLOAD_SHARE_ORG_SUCCESS,
      payload: id
  });

  export const shareUploadOrgError = (error) => ({
      type: UPLOAD_SHARE_ORG_ERROR,
      payload: error
  });
