import {
  ALIGN_LOCATION_GET_LIST,
  ALIGN_LOCATION_GET_LIST_SUCCESS,
  ALIGN_LOCATION_GET_LIST_ERROR,
  ALIGN_LOCATION_GET_CANDIDATE_LIST,
  ALIGN_LOCATION_GET_CANDIDATE_LIST_SUCCESS,
  ALIGN_LOCATION_GET_CANDIDATE_LIST_ERROR,
} from '../actions';

import {createLocationMasterOrientation} from '../../helpers/Utils';

const INIT_STATE = {
	loading: true,

	allItems: null,
  items: null,
  selectedItem: null,
  masterOrientation: null,
  allMasterOrientation: null,

  classificationCandidatesLoading: true,
  classificationCandidates: null,
  selectedClassificationCandidateIndex: -1
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

    case ALIGN_LOCATION_GET_LIST:
    // console.log('REDUCER ALIGN_GET_LIST',action.payload)
		return { ...state, items: null, allItems: null, allMasterOrientation: null, masterOrientation: null, loading: false  };

		case ALIGN_LOCATION_GET_LIST_SUCCESS:
    // console.log('REDUCER ALIGN_LOCATION_GET_LIST_SUCCESS',action.payload)
    const masterOrientation = createLocationMasterOrientation(action.payload.items);
		return { ...state, loading: true,
												allItems: action.payload.items,
                        items: action.payload.items,
                        allMasterOrientation:  masterOrientation,
                        masterOrientation:  masterOrientation,
						};

		case ALIGN_LOCATION_GET_LIST_ERROR:
    // console.log('REDUCER ALIGN_LOCATION_GET_LIST_ERROR',action.payload)
			return { ...state,  loading: false, error: action.payload };

    /*
     * classificationCandidates
     */
	 case ALIGN_LOCATION_GET_CANDIDATE_LIST:
      // console.log('REDUCER ALIGN_LOCATION_GET_CANDIDATE_LIST selectedItem', action.payload)
			return { ...state, selectedItem: action.payload, classificationCandidates: null, selectedClassificationCandidateIndex:-1, classificationCandidatesLoading: false };


		case ALIGN_LOCATION_GET_CANDIDATE_LIST_SUCCESS:
    // console.log('REDUCER ALIGN_LOCATION_GET_CANDIDATE_LIST_SUCCESS selectedItem', action.payload)
			return { ...state, classificationCandidatesLoading: true,
												classificationCandidates: action.payload,
													};

		case ALIGN_LOCATION_GET_CANDIDATE_LIST_ERROR:
    // console.log('REDUCER ALIGN_LOCATION_GET_CANDIDATE_LIST_ERROR selectedItem', action.payload)
			return { ...state, classificationCandidatesLoading: true, error: action.payload };


		default: return { ...state };
	}
}
