import {
  SUPPLYPLAN_GET_DATA,
  SUPPLYPLAN_GET_DATA_SUCCESS,
  SUPPLYPLAN_GET_DATA_ERROR,
  SUPPLYPLAN_CHANGE_PRODUCT,
  SUPPLYPLAN_CHANGE_COUNTRY,
  SUPPLYPLAN_CHANGE_UPLOAD,
  SUPPLYPLAN_GET_METADATA,
  SUPPLYPLAN_GET_METADATA_SUCCESS,
  SUPPLYPLAN_GET_METADATA_ERROR,
  SUPPLYPLAN_SET_METADATA,
  SUPPLYPLAN_SET_METADATA_SUCCESS,
  SUPPLYPLAN_SET_METADATA_ERROR,
} from '../actions';

import {createSupplyPlanMasterOrientation} from '../../helpers/Utils';

const INIT_STATE = {
	loading: true,
  allSupplyPlans: [],             //all supply plans
  selectedMasterOrientation: null,   //the (merged as needed, across supply plans), masterorientation, on which the changeitem operates
  masterOrientation: null,   //the data object country-product, on which the changecountry and changeproduct operate
  countryList: [],
  productsWithTags: [],
  selectedCountry: null,
  selectedProduct: null,
  selectedProductTag: null,
  supplyPlanConfig: null,
  dataTypes: null
};


export default (state = INIT_STATE, action) => {
	switch (action.type) {

    /* same as get_data really, except no API call */
    case SUPPLYPLAN_CHANGE_UPLOAD:
    // console.log('REDUCER SUPPLYPLAN_CHANGE_UPLOAD',action.payload);
      var selectedMasterOrientation = createSupplyPlanMasterOrientation(action.payload.upload_item, state.allSupplyPlans)
      // console.log('selectedMasterOrientation ',selectedMasterOrientation)
      var countryList =  Object.keys(selectedMasterOrientation).sort();
      var firstProductTagInFirstAlphaCountry = Object.keys(selectedMasterOrientation[countryList[0]])[0];
      var firstProductInFirstAlphaCountry = Object.keys(selectedMasterOrientation[countryList[0]][firstProductTagInFirstAlphaCountry])[0];
      var masterOrientation =  selectedMasterOrientation[countryList[0]][firstProductTagInFirstAlphaCountry][firstProductInFirstAlphaCountry];
// console.log('masterOrientation ',masterOrientation)
        return { ...state, loading: true,
            selectedMasterOrientation: selectedMasterOrientation,
            masterOrientation: masterOrientation, //the country-product data selected
            countryList: countryList,
            selectedCountry: countryList[0],
            selectedProductTag: firstProductTagInFirstAlphaCountry,
            selectedProduct: firstProductInFirstAlphaCountry,
            productsWithTags: selectedMasterOrientation[countryList[0]],
        }


    case SUPPLYPLAN_GET_DATA:
      // console.log('REDUCER SUPPLYPLAN_GET_DATA',action.payload)
		  return { ...state,loading: false  };

    //Getting the data from the API, returns the first one, unless 'all' was selected - it came from the side menu
    /* payload needs to include the uploadItem */
		case SUPPLYPLAN_GET_DATA_SUCCESS:
      // console.log('REDUCER SUPPLYPLAN_GET_DATA_SUCCESS',action.payload);
      //if all, then selected will be all
      var allSupplyPlans = action.payload.supply_plans;
      var selectedMasterOrientation = createSupplyPlanMasterOrientation(action.payload.upload_item, allSupplyPlans)
      var countryList =  Object.keys(selectedMasterOrientation).sort();
      var firstProductTagInFirstAlphaCountry = Object.keys(selectedMasterOrientation[countryList[0]])[0];
      var firstProductInFirstAlphaCountry = Object.keys(selectedMasterOrientation[countryList[0]][firstProductTagInFirstAlphaCountry])[0];
      var masterOrientation =  selectedMasterOrientation[countryList[0]][firstProductTagInFirstAlphaCountry][firstProductInFirstAlphaCountry];
        return { ...state, loading: true,
            allSupplyPlans: allSupplyPlans,
            selectedMasterOrientation: selectedMasterOrientation,
            masterOrientation: masterOrientation, //the country-product data selected
            countryList: countryList,
            selectedCountry: countryList[0],
            selectedProductTag: firstProductTagInFirstAlphaCountry,
            selectedProduct: firstProductInFirstAlphaCountry,
            productsWithTags: selectedMasterOrientation[countryList[0]],
        }

		case SUPPLYPLAN_GET_DATA_ERROR:
			return { ...state,  loading: false, error: action.payload };

    /* payload needs to include the uploadItem */
    case SUPPLYPLAN_CHANGE_COUNTRY:
      // console.log('REDUCER SUPPLYPLAN_CHANGE_COUNTRY payload',action.payload);
      //if the prior product is not in this new list, then use the first product?
      const newFirstProductTag = Object.keys(state.selectedMasterOrientation[action.payload.country])[0];
      const newFirstProduct = Object.keys(state.selectedMasterOrientation[action.payload.country][newFirstProductTag])[0];
  		return { ...state, loading: true,
              masterOrientation: state.selectedMasterOrientation[action.payload.country][newFirstProductTag][newFirstProduct],
              selectedCountry: action.payload.country,
              selectedProduct: newFirstProduct, //selectedProduct,
              selectedProductTag: newFirstProductTag,
              productsWithTags: state.selectedMasterOrientation[action.payload.country],
  						};

    /* payload needs to include the uploadItem */
    case SUPPLYPLAN_CHANGE_PRODUCT:
    // console.log('REDUCER SUPPLYPLAN_CHANGE_PRODUCT payload',action.payload);
  		return { ...state, loading: true,
              selectedProduct: action.payload.product,
              selectedProductTag: action.payload.product_tag,
              masterOrientation: state.selectedMasterOrientation[state.selectedCountry][action.payload.product_tag][action.payload.product]
  						};

    //METADATA
    case SUPPLYPLAN_GET_METADATA:
      // console.log('REDUCER SUPPLYPLAN_GET_DATA',action.payload)
      return { ...state,loading: false  };

    case SUPPLYPLAN_GET_METADATA_SUCCESS:
      // console.log('REDUCER SUPPLYPLAN_GET_METADATA_SUCCESS',action.payload);
      return { ...state, loading: true,
              supplyPlanConfig: action.payload.supply_plan,
              dataTypes: action.payload.data_types,
      				};

    case SUPPLYPLAN_GET_METADATA_ERROR:
    	return { ...state,  loading: false, error: action.payload };


    //SET METADATA
		case SUPPLYPLAN_SET_METADATA:
      return { ...state,loading: false  };

		case SUPPLYPLAN_SET_METADATA_SUCCESS:
     // console.log('REDUCER SUPPLYPLAN_SET_METADATA_SUCCESS',  action.payload)
		 return { ...state, supplyPlanConfig: action.payload };

		case SUPPLYPLAN_SET_METADATA_ERROR:
      return { ...state,  loading: false, error: action.payload };


		default: return { ...state };
	}
}
