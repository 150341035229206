import {
    SUPPLYPLAN_GET_DATA,
    SUPPLYPLAN_GET_DATA_SUCCESS,
    SUPPLYPLAN_GET_DATA_ERROR,
    SUPPLYPLAN_CHANGE_PRODUCT,
    SUPPLYPLAN_CHANGE_COUNTRY,
    SUPPLYPLAN_CHANGE_UPLOAD,
    SUPPLYPLAN_GET_METADATA,
    SUPPLYPLAN_GET_METADATA_SUCCESS,
    SUPPLYPLAN_GET_METADATA_ERROR,
    SUPPLYPLAN_SET_METADATA,
    SUPPLYPLAN_SET_METADATA_SUCCESS,
    SUPPLYPLAN_SET_METADATA_ERROR,
} from '../actions';

/*
 * Get data for charts
 */
 export const getData = (upload_item, s3_presignedurl_list) => ({
     type: SUPPLYPLAN_GET_DATA,
     payload: { upload_item, s3_presignedurl_list }
 });

export const getDataSuccess = (items) => ({
    type: SUPPLYPLAN_GET_DATA_SUCCESS,
    payload: items
});

export const getDataError = (error) => ({
    type: SUPPLYPLAN_GET_DATA_ERROR,
    payload: error
});


/*
 * change supply plan/s
 */
export const changeSupplyPlanUpload = (upload_item) => ({
    type: SUPPLYPLAN_CHANGE_UPLOAD,
    payload: { upload_item }
});


/*
 * change country
 */
export const changeCountry = (upload_item, country) => ({
    type: SUPPLYPLAN_CHANGE_COUNTRY,
    payload: { upload_item, country }
});

/*
 * change product
 */
export const changeProduct = (upload_item, product, product_tag) => ({
    type: SUPPLYPLAN_CHANGE_PRODUCT,
    payload: { upload_item, product, product_tag }
});


/*
 * Get metadata for configuration
 */
export const getMetadata = (id) => ({
    type: SUPPLYPLAN_GET_METADATA,
    payload: id
});

export const getMetadataSuccess = (items) => ({
    type: SUPPLYPLAN_GET_METADATA_SUCCESS,
    payload: items
});

export const getMetadataError = (error) => ({
    type: SUPPLYPLAN_GET_METADATA_ERROR,
    payload: error
});

/*
 * Set metadata for configuration
 */
export const setMetadata = (payload ) => ({
    type: SUPPLYPLAN_SET_METADATA,
    payload: payload
});

export const setMetadataSuccess = (items) => ({
    type: SUPPLYPLAN_SET_METADATA_SUCCESS,
    payload: items
});

export const setMetadataError = (error) => ({
    type: SUPPLYPLAN_SET_METADATA_ERROR,
    payload: error
});
