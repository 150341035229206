import {
    UPLOAD_GET_LIST,
    UPLOAD_GET_LIST_SUCCESS,
    UPLOAD_GET_LIST_ERROR,
    UPLOAD_CHANGE_SELECTION,
    UPLOAD_CHANGE_SELECTION_2,
    UPLOAD_SUBMIT,
    UPLOAD_SUBMIT_SUCCESS,
    UPLOAD_SUBMIT_ERROR,
    UPLOAD_DELETE,
    UPLOAD_DELETE_SUCCESS,
    UPLOAD_DELETE_ERROR,
    UPLOAD_SHARE_USER,
    UPLOAD_SHARE_USER_SUCCESS,
    UPLOAD_SHARE_USER_ERROR,
    UPLOAD_SHARE_ORG,
    UPLOAD_SHARE_ORG_SUCCESS,
    UPLOAD_SHARE_ORG_ERROR,
} from '../actions';

import {sort_arr_by_json_key_desc} from '../../helpers/Utils'

const INIT_STATE = {
	loading: true,
  error: null,

  allUploadItems: null,
  //need to maintain lists separately for each refdata
  allCompletedProductUploads: null,
  allCompletedCountryUploads: null,
  allCompletedLocationUploads: null,
  allSupplyPlanUploads: null,

  selectedUploadItem: null,
  selectedUploadId: null,
  selectedUploadTitle: null,
  selectedUploadItem_2: null,
  selectedUploadId_2: null,
  selectedUploadTitle_2: null,

  tradeItemEntriesClassified: 0,
  tradeItemEntriesSharedWithMe: 0,
  tradeItemEntriesSharedWithOthers: 0,
  tradeItemPopularity: null,
  tradeItemTagPopularity: null,

  countryEntriesClassified: 0,
  countryEntriesSharedWithMe: 0,
  countryEntriesSharedWithOthers: 0,

  locationEntriesClassified: 0,
  locationEntriesSharedWithMe: 0,
  locationEntriesSharedWithOthers: 0,

  uploadS3Key: "",
  uploadTitle: "",
  allDataItem:  {id: "ALL", title: "ALL", record_type:"upload"}
};

export const calcEntriesClassified = (total,num) => {
  return total + num;
}

export default (state = INIT_STATE, action) => {
	switch (action.type) {

    //use the title to change selection.  unless it's "all" then allow that through
    case UPLOAD_CHANGE_SELECTION:
console.log('UPLOAD_CHANGE_SELECTION',action.payload)
      if ( action.payload.id === state.allDataItem.id )
        return { ...state, selectedUploadTitle: action.payload, selectedUploadItem: state.allDataItem };
      else {
        const findUpload = state.allUploadItems ? state.allUploadItems.find(x => x.id === action.payload.id ) : null;
        return { ...state, selectedUploadTitle: action.payload, selectedUploadItem: findUpload };
      }
    //use the title to change selection
    case UPLOAD_CHANGE_SELECTION_2:
      const findUpload_2 = state.allUploadItems ? state.allUploadItems.find(x => x.id === action.payload.id ) : null;
      return { ...state, selectedUploadTitle_2: action.payload, selectedUploadItem_2: findUpload_2 };

    //set the UploadId if one was specified
		case UPLOAD_GET_LIST:
// console.log('selectedUploadId',action.payload)
	     return { ...state, allUploadItems: null, allUploadItemsComplete: null,  selectedUploadId: action.payload ? action.payload : null,selectedUploadId_2: action.payload ? action.payload : null, loading: false, error:null };

		case UPLOAD_GET_LIST_SUCCESS:
// console.log('REDUCER UPLOAD_GET_LIST_SUCCESS', action.payload);
// console.log('alluploaditems', action.payload.trade_item_uploads.length > 0 ? sort_arr_by_json_key_desc(action.payload.trade_item_uploads, "created_at") : [])
      let allCompletedProductUploads = []
      let allCompletedCountryUploads = []
      let allCompletedLocationUploads = []
      let allSupplyPlanUploads = [];
      //even if the upload is shared, the shared entry will have the details filled in on processing_status and entries_classified
      action.payload.uploads.forEach( x => {
        if ( ( x.trade_item.processing_status === 'Complete' && x.trade_item.entries_classified > 0 ) ) allCompletedProductUploads.push(x);
        if ( ( x.country.processing_status === 'Complete' && x.country.entries_classified > 0 )  ) allCompletedCountryUploads.push(x);
        if ( ( x.location.processing_status === 'Complete' && x.location.entries_classified > 0 )  ) allCompletedLocationUploads.push(x);
        if ( ( x.supply_plan_s3_presignedurl !== 'None' ) ) allSupplyPlanUploads.push(x);
      });

      //the first uploaditem is set to "ALL" if that was selected
      const findUploadItem = state.selectedUploadId === state.allDataItem.id ? state.allDataItem : action.payload.uploads.find(x => x.id === state.selectedUploadId )

      const findUploadItem_2 = action.payload.uploads.find(x => x.id === state.selectedUploadId_2 )
      const firstProductUploadComplete = action.payload.uploads.find(x => (x.trade_item.processing_status === 'Complete' && x.trade_item.entries_classified > 0) || x.shared)
      const secondProductUploadComplete = action.payload.uploads.find(x => ((x.trade_item.processing_status === 'Complete' && x.trade_item.entries_classified > 0) || x.shared) && x.id !== firstProductUploadComplete.id)
      return { ...state, loading: true,
                allUploadItems:  action.payload.uploads.length > 0 ? sort_arr_by_json_key_desc(action.payload.uploads, "created_at") : [],
                allCompletedProductUploads:  allCompletedProductUploads.length > 0 ? sort_arr_by_json_key_desc(allCompletedProductUploads, "created_at") : [],
                allCompletedCountryUploads:  allCompletedCountryUploads.length > 0 ? sort_arr_by_json_key_desc(allCompletedCountryUploads, "created_at") : [],
                allCompletedLocationUploads:  allCompletedLocationUploads.length > 0 ? sort_arr_by_json_key_desc(allCompletedLocationUploads, "created_at") : [],
                allSupplyPlanUploads:  allSupplyPlanUploads.length > 0 ? sort_arr_by_json_key_desc(allSupplyPlanUploads, "created_at") : [],

                tradeItemEntriesClassified: action.payload.trade_item.entries_classified,
                tradeItemEntriesSharedWithMe: action.payload.trade_item.entries_shared_with_me,
                tradeItemEntriesSharedWithOthers: action.payload.trade_item.entries_shared_with_others,
                tradeItemPopularity: action.payload.trade_item.popularity,
                tradeItemTagPopularity: action.payload.trade_item.popularity_tags,

                countryEntriesClassified: action.payload.country.entries_classified,
                countryEntriesSharedWithMe: action.payload.country.entries_shared_with_me,
                countryEntriesSharedWithOthers: action.payload.country.entries_shared_with_others,

                locationEntriesClassified: action.payload.location.entries_classified,
                locationEntriesSharedWithMe: action.payload.location.entries_shared_with_me,
                locationEntriesSharedWithOthers: action.payload.location.entries_shared_with_others,

                selectedUploadItem: findUploadItem !== undefined ? findUploadItem : firstProductUploadComplete,
                selectedUploadItem_2: findUploadItem_2 !== undefined ? findUploadItem_2 : secondProductUploadComplete
							};

		case UPLOAD_GET_LIST_ERROR:
console.log('UPLOAD_GET_LIST_ERROR')
			return { ...state, loading: true, error: action.payload };

    case UPLOAD_SUBMIT:
      return { ...state, loading: false };

		case UPLOAD_SUBMIT_SUCCESS:
      //on successful api submit, add the new item - the action.payload - to the front
      state.allUploadItems.unshift(action.payload)
		  return { ...state, allUploadItems: state.allUploadItems, loading: true, error: null };

    case UPLOAD_SUBMIT_ERROR:
		  return { ...state, loading: true, error: action.payload };

    case UPLOAD_DELETE:
    // console.log('REDUCER UPLOAD_DELETE')
      return { ...state, loading: false };

		case UPLOAD_DELETE_SUCCESS:
      //on successful api delete, find and remove the item from 'allUploadItems'
// console.log('REDUCER UPLOAD_DELETE_SUCCESS')
      return { ...state, loading: true, allUploadItems: state.allUploadItems.filter( (x) => x.id !== action.payload ), error: null };

    case UPLOAD_DELETE_ERROR:
		  return { ...state, loading: true, error: action.payload };

    case UPLOAD_SHARE_USER:
    case UPLOAD_SHARE_ORG:
      return { ...state, loading: false };

		case UPLOAD_SHARE_USER_SUCCESS:
    case UPLOAD_SHARE_ORG_SUCCESS:
      //on successful api share, update the all-items so it knows there was a new share
      return { ...state, loading: true, error: null };

    case UPLOAD_SHARE_USER_ERROR:
    case UPLOAD_SHARE_ORG_ERROR:
		  return { ...state, loading: true, error: action.payload };

		default: return { ...state };
	}
}
