import {
	SEARCH_LOCATION_GET_LIST_CANDIDATES,
	SEARCH_LOCATION_GET_LIST_CANDIDATES_SUCCESS,
	SEARCH_LOCATION_CLEAR_LIST_CANDIDATES,
	SEARCH_LOCATION_GET_LIST,
	SEARCH_LOCATION_GET_LIST_SUCCESS,
	SEARCH_LOCATION_GET_LIST_ERROR,
	SEARCH_LOCATION_CHANGE_COUNTRY,
	SEARCH_LOCATION_CHANGE_VIEWPORT,
} from '../actions';
import {createLocationMasterOrientation} from '../../helpers/Utils';


const INIT_STATE = {
	searchKeyword: '',
	allSearchItemsCodes: null,
	allMasterOrientation: {},
	allCount: 0,
	allSearchItems: null,
	searchItems: null,

	candidateSearchItems: null,
	candidateSearchKeyword: '',
	candidatesLoading: true,

	loading: true,
	error: '',
	filter: { "column" : "domain", "value" : "Location" },
	uploads: [],
	orderColumn: null,
	orderColumns: [
		{ column: "title", label: "Title" },
		{ column: "category", label: "Category" },
		{ column: "status", label: "Status" },
		{ column: "label", label: "Label" },
	],
	domains: ["Location"],
	countryIso: "ng",
	countryName: "Nigeria",
	lat: 9,
	lng: 8.5,
	zoom: 6,
	countries: {
		"ng": {
			countryName: "Nigeria",
			countryIso: "ng",
			lat: 9,
			lng: 8.5,
			zoom: 6,
		},
		"tz": {
			countryName: "Tanzania",
			countryIso: "tz",
			lat: -6,
			lng: 35,
			zoom: 6,
		}
	},
	productMetadataEntries: 0
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

		/*
		* search, no user values
		*/
		case SEARCH_LOCATION_GET_LIST_CANDIDATES:
			console.log('REDUCER: SEARCH_LOCATION_GET_LIST_CANDIDATES',action.payload)
			return { ...state, candidateSearchKeyword: action.payload, candidateSearchItems:null, candidatesLoading: false };

		case SEARCH_LOCATION_GET_LIST_CANDIDATES_SUCCESS:
			//go through the user values and pull out the filter results
	 	console.log('REDUCER: SEARCH_LOCATION_GET_LIST_CANDIDATES_SUCCESS',action.payload)
			return { ...state, candidatesLoading: true,
													candidateSearchItems: action.payload
													};

		case SEARCH_LOCATION_CLEAR_LIST_CANDIDATES:
			return { ...state, candidateSearchKeyword: '', candidateSearchItems:null, candidatesLoading: true };

		/*
		 * change the active country, and set viewport values
		 */
		case SEARCH_LOCATION_CHANGE_COUNTRY:
		// console.log('REDUCER: SEARCH_LOCATION_CHANGE_COUNTRY',action.payload)
			return { ...state,countryIso: action.payload.countryIso,
							countryName: state.countries[action.payload.countryIso].countryName,
							lat: state.countries[action.payload.countryIso].lat,
							lng: state.countries[action.payload.countryIso].lng,
							zoom: state.countries[action.payload.countryIso].zoom }

		/*
		 * just change the viewport values
		 */
		case SEARCH_LOCATION_CHANGE_VIEWPORT:
			return { ...state, lat: action.payload.lat, lng: action.payload.lng, zoom: action.payload.zoom }

		/*
		 * search, with user values
 		 */
		case SEARCH_LOCATION_GET_LIST:
			// console.log('REDUCER: SEARCH_LOCATION_GET_LIST',action.payload)
			return { ...state, searchKeyword: action.payload, searchItems:null, allSearchItemsCodes: null, filter: null, loading: false };

		case SEARCH_LOCATION_GET_LIST_SUCCESS:
			//go through the user values and pull out the filter results
			// console.log('REDUCER: SEARCH_LOCATION_GET_LIST_SUCCESS',action.payload)
			// console.log('REDUCER: createMasterOrientation',createLocationMasterOrientation(action.payload))

			let uploadsReferenced = {}
			let allCount = 0;

			//build the list of uploads referenced.
			//TODO: move to API
			action.payload.forEach( result => {
				if ( result.hasOwnProperty('user_values') )	 {
  				if ( result.user_values.length === 0 ) allCount += 1;

					result.user_values.forEach( u => {
						if ( uploadsReferenced.hasOwnProperty(u.id)) {
							uploadsReferenced[u.id]['count'] += 1;
						} else {
							uploadsReferenced[u.id] = { 'id': u.id, 'title' : u.title, 'count': 1, 'shared':u.shared}
						}
						allCount += 1;
					});
				}
			});


			return { ...state, loading: true,
 													uploads: Object.values(uploadsReferenced),
													allCount:allCount,
													allMasterOrientation: createLocationMasterOrientation(action.payload),
													allSearchItems: action.payload,
													searchItems: action.payload,
													lat: state.countries[state.countryIso].lat,		//reset viewport too
													lng: state.countries[state.countryIso].lng,
													zoom: state.countries[state.countryIso].zoom
													};

		case SEARCH_LOCATION_GET_LIST_ERROR:
			return { ...state, loading: true, error: action.payload };

		default: return { ...state };
	}
}
