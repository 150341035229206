import {
    ALIGN_GET_LIST,
    ALIGN_GET_LIST_SUCCESS,
    ALIGN_GET_LIST_ERROR,
    ALIGN_GET_LIST_WITH_FILTER,
    ALIGN_GET_CANDIDATE_LIST,
    ALIGN_GET_CANDIDATE_LIST_SUCCESS,
    ALIGN_GET_CANDIDATE_LIST_ERROR,
    ALIGN_CHANGE,
    ALIGN_CHANGE_SUCCESS,
    ALIGN_CHANGE_ERROR,
} from '../actions';


/*
  changes
*/
export const getAlignListWithFilter = (items) => ({
    type: ALIGN_GET_LIST_WITH_FILTER,
    payload: items
});

/*
 * Get list of items to be aligned
 */
export const getAlignList = (selectedUploadItem,s3_presignedurl_list) => ({
    type: ALIGN_GET_LIST,
    payload: {selectedUploadItem, s3_presignedurl_list}
});

export const getAlignListSuccess = (items) => ({
    type: ALIGN_GET_LIST_SUCCESS,
    payload: items
});

export const getAlignListError = (error) => ({
    type: ALIGN_GET_LIST_ERROR,
    payload: error
});


/*
 * Get classification candidates
 */
export const getCandidateList = (id,nk,packSizeStr) => ({
    type: ALIGN_GET_CANDIDATE_LIST,
    payload: { id, nk, packSizeStr }
});

export const getCandidateListSuccess = (items) => ({
    type: ALIGN_GET_CANDIDATE_LIST_SUCCESS,
    payload: items
});

export const getCandidateListError = (error) => ({
    type: ALIGN_GET_CANDIDATE_LIST_ERROR,
    payload: error
});


/*
 * Change alignment
 */
 export const changeAlignment = (tradeItem, matchEntry) => ({
     type: ALIGN_CHANGE,
     payload: {tradeItem, matchEntry }
 });

 export const changeAlignmentSuccess = (items) => ({
     type: ALIGN_CHANGE_SUCCESS,
     payload: items
 });

 export const changeAlignmentError = (error) => ({
     type: ALIGN_CHANGE_ERROR,
     payload: error
 });
