
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { API, Auth } from "aws-amplify";
import awsConfig from '../../helpers/Aws.js'

import {
    LOGIN_USER,
    REGISTER_USER,
    VERIFY_USER,
    LOGOUT_USER,
    // FORGOT_PASSWORD,
    // RESET_PASSWORD,
} from '../actions';

import {
    loginUserSuccess,
    loginUserError,
    registerUserSuccess,
    registerUserError,
    verifyUserSuccess,
    verifyUserError,
    // forgotPasswordSuccess,
    // forgotPasswordError,
    // resetPasswordSuccess,
    // resetPasswordError
} from './actions';


export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const apiGetApiKey  = async () =>
  await API.get(awsConfig.apiGateway.NAME, '/api/api-key')
        .then(apiKey => apiKey)
        .catch(error => error);

const apiGetOrgUsage  = async () =>
  await API.get(awsConfig.apiGateway.NAME, '/org/usage')
        .then(orgUsage => orgUsage)
        .catch(error => error);

const loginWithEmailPasswordAsync = async (email, password) =>
  await Auth.signIn(email,password)
        .then(authUser => authUser)
        .catch(error => error);

function* loginWithEmailPassword({ payload }) {
    const { email, password } = payload.user;
    const { history } = payload;
    try {
        const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
        if ( !loginUser.message ) {
          localStorage.setItem('mosaic-useremail', loginUser.username);
          localStorage.setItem('mosaic-username', loginUser.username.split('@')[0]);
          localStorage.setItem('mosaic-userorg',loginUser.username.split('@')[1].replaceAll('.','-'));
          localStorage.setItem('mosaic-token',loginUser.signInUserSession.accessToken.jwtToken);
          const apiKey = yield call(apiGetApiKey);
          localStorage.setItem('mosaic-api-key',apiKey);
          const orgUsage = yield call(apiGetOrgUsage);
          localStorage.setItem('mosaic-orgusage',orgUsage.usage_org);
          localStorage.setItem('mosaic-userusage',orgUsage.usage);
          localStorage.setItem('mosaic-orglimit',orgUsage.limit);
          yield put(loginUserSuccess(loginUser,apiKey,orgUsage));
          history.push('/');
        } else {
          yield put(loginUserError(loginUser.message));
        }
    } catch (error) {
        yield put(loginUserError(error));

    }
}

export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (email, password) =>
    await Auth.signUp({
          username: email,
          attributes: {
            email: email
          },
          password: password})
        .then(authUser => authUser)
        .catch(error => error);

function* registerWithEmailPassword({ payload }) {
    const { email, password } = payload.user;
    const { history } = payload
    try {
        const registerUser = yield call(registerWithEmailPasswordAsync, email, password);
        localStorage.setItem('mosaic-useremail', email);
        const apiKey = yield call(apiGetApiKey);
        localStorage.setItem('mosaic-api-key',apiKey);
        const orgUsage = yield call(apiGetOrgUsage);
        localStorage.setItem('mosaic-orgusage',orgUsage.usage_org);
        localStorage.setItem('mosaic-userusage',orgUsage.usage);
        localStorage.setItem('mosaic-orglimit',orgUsage.limit);

        yield put(registerUserSuccess(registerUser.user, apiKey, orgUsage));
        history.push('/user/verify')
    } catch (error) {
        yield put(registerUserError(error));
    }
}

export function* watchVerifyUser() {
    yield takeEvery(VERIFY_USER, verifyWithCode);
}

const verifyWithCodeAsync = async (email, verificationCode) =>
    await Auth.confirmSignUp(email,verificationCode)
        .then(authUser => authUser)
        .catch(error => error);

function* verifyWithCode({ payload }) {
    const { verificationCode } = payload.user;
    const { history } = payload
    try {
        const email = localStorage.getItem('mosaic-useremail');
        const verifyUser = yield call(verifyWithCodeAsync, email, verificationCode);
        if ( verifyUser === 'SUCCESS' || verifyUser.message.includes('Current status is CONFIRMED')) {
          yield put(verifyUserSuccess(verifyUser));
          history.push('/user/login');
        } else {
            yield put(verifyUserError(verifyUser.message));
        }
    } catch (error) {
        yield put(verifyUserError(error));
    }
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
    await Auth.signOut({ global: true }).then(authUser => authUser).catch(error => error);
    history.push('/')
}

function* logout({ payload }) {
    const { history } = payload
    try {
        yield call(logoutAsync, history);
        localStorage.removeItem('mosaic-useremail');
        localStorage.removeItem('mosaic-username');
        localStorage.removeItem('mosaic-userorg');
        localStorage.removeItem('mosaic-orgusage');
        localStorage.removeItem('mosaic-userusage');
        localStorage.removeItem('mosaic-orglimit');
        localStorage.removeItem('mosaic-token');
        localStorage.removeItem('mosaic-api-key');
    } catch (error) {
    }
}

// export function* watchForgotPassword() {
//     yield takeEvery(FORGOT_PASSWORD, forgotPassword);
// }
//
// const forgotPasswordAsync = async (email) => {
//     return await auth.sendPasswordResetEmail(email)
//         .then(user => user)
//         .catch(error => error);
// }
//
// function* forgotPassword({ payload }) {
//     const { email } = payload.forgotUserMail;
//     try {
//         const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
//         if (!forgotPasswordStatus) {
//             yield put(forgotPasswordSuccess("success"));
//         } else {
//             yield put(forgotPasswordError(forgotPasswordStatus.message));
//         }
//     } catch (error) {
//         yield put(forgotPasswordError(error));
//
//     }
// }
//
// export function* watchResetPassword() {
//     yield takeEvery(RESET_PASSWORD, resetPassword);
// }
//
// const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
//     return await auth.confirmPasswordReset(resetPasswordCode, newPassword)
//         .then(user => user)
//         .catch(error => error);
// }
//
// function* resetPassword({ payload }) {
//     const { newPassword, resetPasswordCode } = payload;
//     try {
//         const resetPasswordStatus = yield call(resetPasswordAsync, resetPasswordCode, newPassword);
//         if (!resetPasswordStatus) {
//             yield put(resetPasswordSuccess("success"));
//         } else {
//             yield put(resetPasswordError(resetPasswordStatus.message));
//         }
//     } catch (error) {
//         yield put(resetPasswordError(error));
//
//     }
// }

export default function* rootSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
        fork(watchVerifyUser),
        // fork(watchForgotPassword),
        // fork(watchResetPassword),
    ]);
}
