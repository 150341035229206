import { defaultDirection } from "../constants/defaultValues";

export const crc32=function(r){for(var a,o=[],c=0;c<256;c++){a=c;for(var f=0;f<8;f++)a=1&a?3988292384^a>>>1:a>>>1;o[c]=a}for(var n=-1,t=0;t<r.length;t++)n=n>>>8^o[255&(n^r.charCodeAt(t))];return(-1^n)>>>0};


export const camelize = (str) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
    return word.toUpperCase();
    // return index === 0 ? word.toLowerCase() : word.toUpperCase();
  });
}

export const mapOrder = (array, order, key) => {
  array.sort(function(a, b) {
    var A = a[key],
      B = b[key];
    if (order.indexOf(A + "") > order.indexOf(B + "")) {
      return 1;
    } else {
      return -1;
    }
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!

  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return dd + "." + mm + "." + yyyy;
};

export const getCurrentTime = () => {
  const now = new Date();
  return now.getHours() + ":" + now.getMinutes();
};

export const getDirection = () => {
  let direction = defaultDirection;
  if (localStorage.getItem("direction")) {
    const localValue = localStorage.getItem("direction");
    if (localValue === "rtl" || localValue === "ltr") {
      direction = localValue;
    }
  }
  return {
    direction,
    isRtl: direction === "rtl"
  };
};

export const setDirection = localValue => {
  let direction = "ltr";
  if (localValue === "rtl" || localValue === "ltr") {
    direction = localValue;
  }
  localStorage.setItem("direction", direction);
};

export function numberWithCommas(x) {
  try{
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } catch {
    return "0";
  }
};

export function getSum(total, num) {
  return total + num;
}

export  function padZero(str, len) {
    len = len || 2;
    var zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
}

export function invertHexColor(hex, bw) {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
console.log('hex error',hex)
        throw new Error('Invalid HEX color.');
    }
    var r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
        // http://stackoverflow.com/a/3943023/112731
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186
            ? '#000000'
            : '#FFFFFF';
    }
    // invert color components
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16);
    // pad each with zeros and return
    return "#" + padZero(r) + padZero(g) + padZero(b);
}

export function invertRgbColor(rgb, bw) {
    var colorStr = rgb.replace('rgb','').replace('(','').replace(')','');
    var colorParts = colorStr.split(',');
    if (colorParts.length !== 3 ) {
// console.log('rgb error',rgb,colorStr,colorParts)
        throw new Error('Invalid RGB color.');
    }
    var r = parseInt(colorParts[0]),
        g = parseInt(colorParts[1]),
        b = parseInt(colorParts[2]);
    if (bw) {
        // http://stackoverflow.com/a/3943023/112731
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186
            ? '#000000'
            : '#FFFFFF';
    }
    // invert color components
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16);
    // pad each with zeros and return
    return "#" + padZero(r) + padZero(g) + padZero(b);
}

export function sort_arr_by_json_key (array, key) {
  return array.sort(function(a, b)
    {
      var x = a[key]; var y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }
export function sort_arr_by_json_key_desc (array, key) {
  return array.sort(function(a, b)
    {
      var x = a[key]; var y = b[key];
      return ((x > y) ? -1 : ((x < y) ? 1 : 0));
    });
  }

/*
* handles parentheses, which are not covered in encodeURI or encodeURIComponent
*/
export function superEncodeURI(url) {

    var encodedStr = '', encodeChars = ["(", ")","*"];
    url = encodeURIComponent(url);

    for(var i = 0, len = url.length; i < len; i++) {
      if (encodeChars.indexOf(url[i]) >= 0) {
          var hex = parseInt(url.charCodeAt(i)).toString(16);
          encodedStr += '%' + hex;
      }
      else {
          encodedStr += url[i];
      }
    }

    return encodedStr;
  }

/*
  group up all the trade item objects, by their matched_product_name attribute, and return the pivoted structure
*/
export const createMasterOrientation = (tradeItems) => {
  let masterOrientation = {}
  tradeItems.forEach( result => {
    if ( masterOrientation.hasOwnProperty(result.matched_product_name) ) {
      masterOrientation[result.matched_product_name].user_values.push(result);
    }
    else {

//search result does not use matched-product-entry, it has it 'up top' - interesting difference betwixt
//need to make it the same, no?

      if ( result.hasOwnProperty('matched_product_entry') ) {
        // console.log('createMasterOrientation hasOwnProperty matched_product_entry',result.matched_product_name)
        masterOrientation[result.matched_product_name] = {
          matched_product_entry: {
            l1_code: result.matched_product_entry.l1_code,
            l1_name: result.matched_product_entry.l1_name,
            l2_code: result.matched_product_entry.l2_code,
            l2_name: result.matched_product_entry.l2_name,
            l3_code: result.matched_product_entry.l3_code,
            l3_name: result.matched_product_entry.l3_name,
            l4_code: result.matched_product_entry.l4_code,
            l4_name: result.matched_product_entry.l4_name,
            l5_code: result.matched_product_entry.l5_code,
            l5_name: result.matched_product_entry.l5_name,
            category: result.matched_product_entry.category
          },
          user_values: [result]

        }
      } else {
        console.log('createMasterOrientation whoops')
        masterOrientation[result.matched_product_name] = {
          matched_product_entry: {
            l1_code: result.l1_code,
            l1_name: result.l1_name,
            l2_code: result.l2_code,
            l2_name: result.l2_name,
            l3_code: result.l3_code,
            l3_name: result.l3_name,
            l4_code: result.l4_code,
            l4_name: result.l4_name,
            l5_code: result.l5_code,
            l5_name: result.l5_name,
            category: result.category
          },
          user_values: [result]
        }
      }
    }
  });
  return masterOrientation;


}
/*
  group up all the country objects, by their matched_name attribute, and return the pivoted structure
*/
export const createCountryMasterOrientation = (items) => {
  let masterOrientation = {}
  items.forEach( result => {
    if ( masterOrientation.hasOwnProperty(result.matched_name) ) {
      masterOrientation[result.matched_name].user_values.push(result);
    }
    else {
      if ( result.hasOwnProperty('matched_entry') ) {
        masterOrientation[result.matched_name] = {
          l1_code: result.matched_entry.l1_code,
          l1_name: result.matched_entry.l1_name,
          user_values: [result]
        }
      }
    }
  });
  return masterOrientation;
}

/*
  group up all the country objects, by their matched_name attribute, and return the pivoted structure
  and allow all the info coming in from the match - whatever it may be for the country facilities
*/
export const createLocationMasterOrientation = (items) => {
  let masterOrientation = {}
  items.forEach( result => {
    if ( masterOrientation.hasOwnProperty(result.matched_name) ) {
      masterOrientation[result.matched_name].user_values.push(result);
    }
    else {
      //TODO: allow all matched entry values in here!  minimums, sure. but all the rest?
      //   matched_name: result.matched_name,
      //   l1_name: result.matched_entry.l1_name,
      //   l2_name: result.matched_entry.l2_name,
      //   l3_name: result.matched_entry.l3_name,
      //   l4_name: result.matched_entry.l4_name,
      //   classificationPath: result.matched_entry.classificationPath,
      //   service_level: result.matched_entry.service_level,
      //   service_sector: result.matched_entry.service_sector,
      //   latitude: result.matched_entry.latitude,
      //   longitude: result.matched_entry.longitude,

      if ( result.hasOwnProperty('matched_entry') ) {
        masterOrientation[result.matched_name] = {
          matched_entry: result.matched_entry,
          user_values: [result]
        }
      }
      if ( result.hasOwnProperty('best_nk') ) {
        masterOrientation[result.matched_name]['best_nk'] = result.best_nk
      }

    }
  });
  return masterOrientation;
}

/*
  group up the country-product ... labels/datasets? objects, by their ... ? attribute, and return the pivoted structure
the incoming data needs to be ...?
cant just be selected items, can it?


result needs to be in the form of a chartdata, as parallel arrays
labels
datasets

// */
// function union(setA, setB) {
//     let _union = new Set(setA)
//     for (let elem of setB) {
//         _union.add(elem)
//     }
//     return _union
// }


export const createSupplyPlanMasterOrientation = (uploadItem, allSupplyPlans) => {
console.log('createSupplyPlanMasterOrientation allSupplyPlans',allSupplyPlans);
  if ( uploadItem.id !== 'ALL' ) {
    const the_sp = allSupplyPlans.find( sp => { return sp.id === uploadItem.id });
    let makeDatasetsIntoArray = []
    makeDatasetsIntoArray.push(the_sp.data.datasets)
    the_sp.data.datasets = makeDatasetsIntoArray;
    return the_sp.data;
  }

  //consolidate if ALL
  let masterOrientation = { }
  allSupplyPlans.forEach( sp => {
// console.log('sp',sp)
    let countryList = Object.keys(sp.data);
    countryList.forEach( c => {
      if ( c === 'datasets' || c === 'labels' )
        return

      if ( !masterOrientation.hasOwnProperty(c) ) {
        masterOrientation[c] = {}
      }

      let productTagList = Object.keys(sp.data[c])
      productTagList.forEach( pt => {
        if ( !masterOrientation[c].hasOwnProperty(pt) ) {
          masterOrientation[c][pt] = {}
        }
// console.log('sp ',sp.id,'c pt',c,pt)
        let productList = Object.keys(sp.data[c][pt])
        productList.forEach( p => {
          // console.log('checking p',p)
          if ( !masterOrientation[c][pt].hasOwnProperty(p) ) {
            masterOrientation[c][pt][p] = {}
          }

          let monthList = Object.keys(sp.data[c][pt][p])
          monthList.forEach( m => {
            if ( m !== 'labels' && m !== 'datasets' && !masterOrientation[c][pt][p].hasOwnProperty(m) ) {
              masterOrientation[c][pt][p][m] = { }
              masterOrientation[c][pt][p][m][sp.id] = sp.data[c][pt][p][m]
            }
          }); //monthlist
        }); //productlist
      }); //producttaglist
    }); //countrylist
  }); //supplyplans
  // console.log('mo now ',masterOrientation)

  let countryList = Object.keys(masterOrientation);
  countryList.forEach( c => {
    let productTagList = Object.keys(masterOrientation[c])
    productTagList.forEach( pt => {
      let productList = Object.keys(masterOrientation[c][pt])
      productList.forEach( p => {
        let monthList = Object.keys(masterOrientation[c][pt][p])
        monthList.forEach( m => {
          allSupplyPlans.forEach( sp => {
            if ( sp.data.hasOwnProperty(c)
              && sp.data[c].hasOwnProperty(pt)
              && sp.data[c][pt].hasOwnProperty(p)
              && sp.data[c][pt][p].hasOwnProperty(m) )
            {
              masterOrientation[c][pt][p][m][sp.id] = sp.data[c][pt][p][m]
            } else {
              masterOrientation[c][pt][p][m][sp.id] = 0
            }
          });

        }); //monthlist

        //now we are done with all the months and all the supply plans, so aggregate up and create the labels and datasets
        masterOrientation[c][pt][p]['labels'] = Object.keys(masterOrientation[c][pt][p])
        //add a dataset for each sp
        let datasets = []
        allSupplyPlans.forEach( sp => {
          let this_sp_data = []
          monthList.forEach( m => {
            this_sp_data.push(masterOrientation[c][pt][p][m][sp.id])
          });
          datasets.push({
            id: sp.id,
            title: sp.title,
            data: this_sp_data
          })
        });
        masterOrientation[c][pt][p]['datasets'] = datasets

      }); //productlist
    }); //producttaglist
  }); //countrylist
  // console.log('mo now ',masterOrientation)

  return masterOrientation;
}
