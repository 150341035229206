import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import datalakeSearchApp from './datalakeSearch/reducer';
import datalakeSearchLocationApp from './datalakeSearchLocation/reducer';
import datalakeUploadApp from './datalakeUpload/reducer';
import datalakeAlignApp from './datalakeAlign/reducer';
import datalakeAlignCountryApp from './datalakeAlignCountry/reducer';
import datalakeAlignLocationApp from './datalakeAlignLocation/reducer';
import datalakeVennApp from './datalakeVenn/reducer';
import supplyplanApp from './supplyplan/reducer';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  datalakeSearchApp,
  datalakeSearchLocationApp,
  datalakeUploadApp,
  datalakeAlignApp,
  datalakeAlignCountryApp,
  datalakeAlignLocationApp,
  datalakeVennApp,
  supplyplanApp,
});

export default reducers;
