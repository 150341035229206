import {
	SEARCH_GET_LIST,
	SEARCH_GET_LIST_SUCCESS,
	SEARCH_GET_LIST_ERROR,
	SEARCH_GET_LIST_WITH_FILTER,
	SEARCH_CLEAR_LIST_CANDIDATES,
	SEARCH_GET_LIST_CANDIDATES,
	SEARCH_GET_LIST_CANDIDATES_SUCCESS,
	SEARCH_GET_LIST_CANDIDATES_ERROR,
	SEARCH_GET_PRODUCT_METADATA,
	SEARCH_GET_PRODUCT_METADATA_SUCCESS,
	SEARCH_GET_PRODUCT_METADATA_ERROR,
} from '../actions';
import {createMasterOrientation} from '../../helpers/Utils';

const INIT_STATE = {
	searchKeyword: '',
	allSearchItemsCodes: null,
	allMasterOrientation: {},
	allCount: 0,
	allSearchItems: null,
	searchItems: null,
	candidateSearchItems: null,
	candidateSearchKeyword: '',
	candidatesLoading: true,
	error: '',
	filter: { "column" : "domain", "value" : "Product" },
	uploads: [],
	loading: true,
	orderColumn: null,
	orderColumns: [
		{ column: "title", label: "Title" },
		{ column: "category", label: "Category" },
		{ column: "status", label: "Status" },
		{ column: "label", label: "Label" },
	],
	domains: ["Product"],
	productMetadataEntries: 0
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

		/*
		* search, no user values
		*/
		case SEARCH_GET_LIST_CANDIDATES:
			return { ...state, candidateSearchKeyword: action.payload, candidateSearchItems:null, candidatesLoading: false };

		case SEARCH_GET_LIST_CANDIDATES_SUCCESS:
			//go through the user values and pull out the filter results
	// console.log('REDUCER: SEARCH_GET_LIST_CANDIDATES',action.payload)
			return { ...state, candidatesLoading: true,
													candidateSearchItems: action.payload
													};

			case SEARCH_CLEAR_LIST_CANDIDATES:
				return { ...state, candidateSearchKeyword: '', candidateSearchItems:null, candidatesLoading: true };


		/*
		* search, with user values
		*/
		case SEARCH_GET_LIST:
			return { ...state, searchKeyword: action.payload, searchItems:null, allSearchItemsCodes: null, filter: null, loading: false };

		case SEARCH_GET_LIST_SUCCESS:
			//go through the user values and pull out the filter results
			// console.log('REDUCER: SEARCH_GET_LIST_SUCCESS',action.payload)
			// console.log('REDUCER: createMasterOrientation',createMasterOrientation(action.payload))

			let uploadsReferenced = {}
			let allCount = 0;

			//build the list of uploads referenced.
			//TODO: move to API
			action.payload.forEach( result => {
				if ( result.hasOwnProperty('user_values') )	 {
  				if ( result.user_values.length === 0 ) allCount += 1;

					result.user_values.forEach( u => {
						if ( uploadsReferenced.hasOwnProperty(u.id)) {
							uploadsReferenced[u.id]['count'] += 1;
						} else {
							uploadsReferenced[u.id] = { 'id': u.id, 'title' : u.title, 'count': 1, 'shared':u.shared}
						}
						allCount += 1;
					});
				}
			});

			return { ...state, loading: true,
 													uploads: Object.values(uploadsReferenced),
													allCount:allCount,
													allMasterOrientation: createMasterOrientation(action.payload),
													allSearchItems: action.payload,
													searchItems: action.payload
													};

		case SEARCH_GET_LIST_ERROR:
		case SEARCH_GET_LIST_CANDIDATES_ERROR:
			return { ...state, loading: true, error: action.payload };

		case SEARCH_GET_LIST_WITH_FILTER:
			//reset
			 // console.log('REDUCER SEARCH_GET_LIST_WITH_FILTER',action.payload);
			if (action.payload.column === '' || action.payload.value === '') {
				return { ...state, loading: true, searchItems: state.allSearchItems, filter: null };
			} else {
				//does the payload column equal the filter ?  e.g. 'domain' of product
				const filteredItems = state.allSearchItems.filter((item) =>
					(item[action.payload.column] === action.payload.value) ||
					(action.payload.column === 'upload' && item.user_values.find((u) => { return u.id === action.payload.value} ) !== undefined )
				);
				//or does the payload user-values contain the filter userid
				return {
					...state, loading: true, searchItems: filteredItems, filter: {
						column: action.payload.column,
						value: action.payload.value
					}
				}
			}


		/*
		* product metadata
		*/
		case SEARCH_GET_PRODUCT_METADATA:
			return { ...state, loading: false };

		case SEARCH_GET_PRODUCT_METADATA_SUCCESS:
			return { ...state, loading: true,
													productMetadataEntries: action.payload.row_count
													};

		case SEARCH_GET_PRODUCT_METADATA_ERROR:
			return { ...state, loading: true, error: action.payload };


		default: return { ...state };
	}
}
