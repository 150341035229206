import {
    SEARCH_LOCATION_CLEAR_LIST_CANDIDATES,
    SEARCH_LOCATION_GET_LIST_CANDIDATES,
    SEARCH_LOCATION_GET_LIST_CANDIDATES_SUCCESS,
    SEARCH_LOCATION_GET_LIST_CANDIDATES_ERROR,
    SEARCH_LOCATION_GET_LIST,
    SEARCH_LOCATION_GET_LIST_SUCCESS,
    SEARCH_LOCATION_GET_LIST_ERROR,
    SEARCH_LOCATION_CHANGE_COUNTRY,
    SEARCH_LOCATION_CHANGE_VIEWPORT
} from '../actions';

/*
 * search list no user values
 */
 export const clearSearchLocationListCandidates = () => ({
     type: SEARCH_LOCATION_CLEAR_LIST_CANDIDATES
 });

export const getSearchLocationListCandidates = (searchKeyword) => ({
    type: SEARCH_LOCATION_GET_LIST_CANDIDATES,
    payload: searchKeyword
});

export const getSearchLocationListCandidatesSuccess = (items) => ({
    type: SEARCH_LOCATION_GET_LIST_CANDIDATES_SUCCESS,
    payload: items
});

export const getSearchLocationListCandidatesError = (error) => ({
    type: SEARCH_LOCATION_GET_LIST_CANDIDATES_ERROR,
    payload: error
});


/*
 * change the active country
 */
export const changeSearchLocationCountry = (countryIso) => ({
    type: SEARCH_LOCATION_CHANGE_COUNTRY,
    payload:  {countryIso}
});

export const changeSearchLocationViewport= (lat,lng, zoom) => ({
    type: SEARCH_LOCATION_CHANGE_VIEWPORT,
    payload:  {lat,lng, zoom}
});

/*
 * search list
 */
export const getSearchLocationList = (countryIso,searchKeyword) => ({
    type: SEARCH_LOCATION_GET_LIST,
    payload: {countryIso,searchKeyword}
});

export const getSearchLocationListSuccess = (items) => ({
    type: SEARCH_LOCATION_GET_LIST_SUCCESS,
    payload: items
});

export const getSearchLocationListError = (error) => ({
    type: SEARCH_LOCATION_GET_LIST_ERROR,
    payload: error
});
