import {
    ALIGN_GET_LIST,
    ALIGN_GET_LIST_SUCCESS,
    ALIGN_GET_LIST_ERROR,
    ALIGN_GET_LIST_WITH_FILTER,
    ALIGN_GET_CANDIDATE_LIST,
    ALIGN_GET_CANDIDATE_LIST_SUCCESS,
    ALIGN_GET_CANDIDATE_LIST_ERROR,
    ALIGN_CHANGE,
    ALIGN_CHANGE_SUCCESS,
    ALIGN_CHANGE_ERROR,
} from '../actions';

import {createMasterOrientation} from '../../helpers/Utils';

const INIT_STATE = {
	loading: true,
  error: null,
	allTradeItems: null,
  tradeItems: null,
  selectedTradeItem: null,
  masterOrientation: null,
  allMasterOrientation: null,

  selectedUploadItem: null, //we have to track the selecteduploaditem in this reducer, so we know if it changed when the Upload is changed

  classificationCandidatesLoading: true,
  classificationCandidates: null,
  selectedClassificationCandidateIndex: -1
};



export default (state = INIT_STATE, action) => {
	switch (action.type) {

    case ALIGN_GET_LIST:
      // console.log('REDUCER ALIGN_GET_LIST',new Date();
		  return { ...state, classificationCandidatesLoading: true, tradeItems: null, allTradeItems: null, allMasterOrientation: null, masterOrientation: null, loading: false  };

		case ALIGN_GET_LIST_SUCCESS:
    const masterOrientation = createMasterOrientation(action.payload.trade_items);
		return { ...state, loading: true, error:false,
                        selectedUploadItem:action.payload.selectedUploadItem,
                        classificationCandidatesLoading: true,
												allTradeItems: action.payload.trade_items,
                        tradeItems: action.payload.trade_items,
                        allMasterOrientation:  masterOrientation,
                        masterOrientation:  masterOrientation,
						};

		case ALIGN_GET_LIST_ERROR:
      // console.log('REDUCER ALIGN_GET_LIST_ERROR',action.payload);
			return { ...state,  loading: false, error: action.payload };

    case ALIGN_GET_LIST_WITH_FILTER:
      if ( action.payload.length > 0 ) {
        let tradeItemsFiltered = state.allTradeItems.filter( d => { if ( action.payload.find( s => s === d.record_type) ) return d; else return null; }  );
        return { ...state,
                    tradeItems: tradeItemsFiltered,
                    masterOrientation:  createMasterOrientation(tradeItemsFiltered)
                };
      }
      else {
        return { ...state, tradeItems: state.allTradeItems, masterOrientation: state.allMasterOrientation};
      }

  /*
   * classificationCandidates
   */
		case ALIGN_GET_CANDIDATE_LIST:
// console.log('REDUCER ALIGN_GET_CANDIDATE_LIST selectedTradeItem', action.payload)
			return { ...state, selectedTradeItem: action.payload, classificationCandidates: null, selectedClassificationCandidateIndex:-1, classificationCandidatesLoading: false };

		case ALIGN_GET_CANDIDATE_LIST_SUCCESS:
 // console.log('REDUCER ALIGN_GET_CANDIDATE_LIST_SUCCESS', action.payload)
			return { ...state, classificationCandidatesLoading: true,
												classificationCandidates: action.payload,
													};

		case ALIGN_GET_CANDIDATE_LIST_ERROR:
			return { ...state, classificationCandidatesLoading: true, error: action.payload };


    //will change the alignment for the "selectedTradeItem" to the "selectedClassificationCandidateIndex" within the "classificationCandidates"
		case ALIGN_CHANGE:
    // console.log('REDUCER ALIGN_CHANGE',action.payload)
			return { ...state, classificationCandidatesLoading: false };

		case ALIGN_CHANGE_SUCCESS:
     // console.log('REDUCER ALIGN_CHANGE_SUCCESS',action.payload)

      /*
       * SUNBURST: remap the data structure - no need to hit the api again?
       */
      let selected_matched_product_name = state.classificationCandidates[state.selectedClassificationCandidateIndex].l5_name;
      let selected_match_entry = state.classificationCandidates[state.selectedClassificationCandidateIndex];
      let prior_matched_product_name = null;
      let tradeItemEntry = null;
      let reassignedTradeItems = state.allTradeItems;
      reassignedTradeItems.map( (entry, index)  => {
        if ( entry.record_type === decodeURI(state.selectedTradeItem.nk)) {
          prior_matched_product_name = entry.matched_product_name;
// console.log('FOUND THE ENTRY IN trade items',entry_details)
          entry.matched_product_name = selected_matched_product_name;
          entry.matched_product_entry = selected_match_entry;
          tradeItemEntry = entry;
// console.log('CHANGED IT NOW TO',entry)
        }
        return entry;
      });

      let reassignedTradeItemsSelected = state.tradeItems;
      reassignedTradeItemsSelected.map( (entry, index)  => {
        if ( entry.record_type === decodeURI(state.selectedTradeItem.nk)) {
          entry.matched_product_name = selected_matched_product_name;
          entry.matched_product_entry = selected_match_entry;
        }
        return entry;
      });

      /*
       * MASTER ORIENTATION: remap the data structure - no need to hit the api again
       */
      let reassignedMasterOrientation = state.masterOrientation;

      //remove existing
      reassignedMasterOrientation[prior_matched_product_name].user_values =
          reassignedMasterOrientation[prior_matched_product_name].user_values.filter( function(value, index, arr) {
              return value.record_type !== decodeURI(state.selectedTradeItem.nk);
          });

      //add where it belongs
      if ( reassignedMasterOrientation.hasOwnProperty(selected_matched_product_name) ) {
        reassignedMasterOrientation[selected_matched_product_name].user_values.push(tradeItemEntry);
      }
      else {
        reassignedMasterOrientation[selected_matched_product_name] = {
          l1_code: selected_match_entry.matched_product_entry.l1_code,
          l1_name: selected_match_entry.matched_product_entry.l1_name,
          l2_code: selected_match_entry.matched_product_entry.l2_code,
          l2_name: selected_match_entry.matched_product_entry.l2_name,
          l3_code: selected_match_entry.matched_product_entry.l3_code,
          l3_name: selected_match_entry.matched_product_entry.l3_name,
          l4_code: selected_match_entry.matched_product_entry.l4_code,
          l4_name: selected_match_entry.matched_product_entry.l4_name,
          l5_code: selected_match_entry.matched_product_entry.l5_code,
          l5_name: selected_match_entry.matched_product_entry.l5_name,
          l5_name_normalized: selected_match_entry.matched_product_name_normalized,
          user_values: [tradeItemEntry]
        }
      }

		 return { ...state, classificationCandidatesLoading: true,
                        allTradeItems: reassignedTradeItems,
                        tradeItems: reassignedTradeItemsSelected,
                        allMasterOrientation: reassignedMasterOrientation,
                        masterOrientation: reassignedMasterOrientation	};


		case ALIGN_CHANGE_ERROR:
			return { ...state, classificationCandidatesLoading: true, error: action.payload };



		default: return { ...state };
	}
}
