import {
    SEARCH_GET_LIST,
    SEARCH_GET_LIST_SUCCESS,
    SEARCH_GET_LIST_ERROR,
    SEARCH_GET_LIST_WITH_FILTER,
    SEARCH_CLEAR_LIST_CANDIDATES,
    SEARCH_GET_LIST_CANDIDATES,
    SEARCH_GET_LIST_CANDIDATES_SUCCESS,
    SEARCH_GET_LIST_CANDIDATES_ERROR,
    SEARCH_GET_PRODUCT_METADATA,
    SEARCH_GET_PRODUCT_METADATA_SUCCESS,
  	SEARCH_GET_PRODUCT_METADATA_ERROR,
} from '../actions';


/*
 * search list no user values
 */
 export const clearSearchListCandidates = () => ({
     type: SEARCH_CLEAR_LIST_CANDIDATES
 });

export const getSearchListCandidates = (searchKeyword) => ({
    type: SEARCH_GET_LIST_CANDIDATES,
    payload: searchKeyword
});

export const getSearchListCandidatesSuccess = (items) => ({
    type: SEARCH_GET_LIST_CANDIDATES_SUCCESS,
    payload: items
});

export const getSearchListCandidatesError = (error) => ({
    type: SEARCH_GET_LIST_CANDIDATES_ERROR,
    payload: error
});


/*
 * search list
 */
export const getSearchList = (searchKeyword) => ({
    type: SEARCH_GET_LIST,
    payload: searchKeyword
});

export const getSearchListSuccess = (items) => ({
    type: SEARCH_GET_LIST_SUCCESS,
    payload: items
});

export const getSearchListError = (error) => ({
    type: SEARCH_GET_LIST_ERROR,
    payload: error
});

export const getSearchListWithFilter = (column, value) => ({
    type: SEARCH_GET_LIST_WITH_FILTER,
    payload: { column, value }
});

/*
 * product metadata
 */
 export const getProductMetadata = () => ({
     type: SEARCH_GET_PRODUCT_METADATA,
 });

 export const getProductMetadataSuccess = (meta) => ({
     type: SEARCH_GET_PRODUCT_METADATA_SUCCESS,
     payload: meta
 });

 export const getProductMetadataError = (error) => ({
     type: SEARCH_GET_PRODUCT_METADATA_ERROR,
     payload: error
 });
