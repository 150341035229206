import {
  VENN_GET_LIST,              //list of items from the data set / upload file
  VENN_GET_LIST_SUCCESS,
  VENN_GET_LIST_ERROR,
  VENN_GET_LIST_WITH_FILTER,  //sunburst nav
  VENN_CHANGE_SET_OPERATION,  //set operations
  VENN_UPDATE_ALIGNMENT,
} from '../actions';

import {createMasterOrientation} from '../../helpers/Utils';

const INIT_STATE = {
	loading: true,

  tradeItemsLeft: null,  //trade items from the file
  tradeItemsRight: null,

	allTradeItems: null,        //all of the ones in the result, after SET operations
  tradeItems: null,           //the displayed set, as a result of sunburst selections e.g
  allMasterOrientation: null, //allTradeItems, massaged into sunburst-friendly data structure
  masterOrientation: null,    //this allows for filtering the sunburst based on SET operations

  setOperation: "LR",     //values are L, LMR, INT, RML, R.  Left-minus-Right, Right-minus-Left, and Intersection
};


/*
 * common function
 */
export const doSetOperation = (tradeItemsLeft, tradeItemsRight, setOperation) => {
  // console. log('DataHubVenn doSetOperation start ',time.getHours() + ":" + time)

  let allTradeItems = [];
  //right side could be null, if only 1 data set available
  let use_tradeItemsRight = tradeItemsRight === null ? [] : tradeItemsRight;
  if ( tradeItemsLeft !== null ) {
    switch ( setOperation ) {
      case "R":
        allTradeItems = use_tradeItemsRight;
        break;
      case "RML":
        //return right only if not found in right
        allTradeItems = use_tradeItemsRight.filter( r => { if ( tradeItemsLeft.find( l => l.matched_product_name === r.matched_product_name) ) return null; else return r; }  );
        break;
      case "INT":
        //return left AND right trade items, if found in both
        allTradeItems = tradeItemsLeft.filter( l => { if ( use_tradeItemsRight.find( r => r.matched_product_name === l.matched_product_name) ) return l; else return null; }  );
        allTradeItems = allTradeItems.concat( use_tradeItemsRight.filter( r => { if ( tradeItemsLeft.find( l => l.matched_product_name === r.matched_product_name) ) return r; else return null;}) );
        break;
      case "LMR":
        //return left only if not found in right
        allTradeItems = tradeItemsLeft.filter( l => { if ( use_tradeItemsRight.find( r => r.matched_product_name === l.matched_product_name) ) return null; else return l; }  );
        break;
      case "LR":
        allTradeItems = tradeItemsLeft.concat(use_tradeItemsRight);
        break;

      case "L":
      default:
        allTradeItems = tradeItemsLeft;
        break;
    }
  }
  const masterOrientation = createMasterOrientation(allTradeItems);
  // console. log('DataHubVenn doSetOperation finish ',time.getHours() + ":" + time)

  return ({ allTradeItems, masterOrientation});

};

/*
 *
 */
export default (state = INIT_STATE, action) => {
	switch (action.type) {

    case VENN_GET_LIST:
    // console.log('REDUCER VENN_GET_LIST',action.payload)
		return { ...state, tradeItems: null, allTradeItems: null, allMasterOrientation: null, masterOrientation: null, loading: false,
          };

		case VENN_GET_LIST_SUCCESS:
 // console.log('REDUCER VENN_GET_LIST_SUCCESS',action.payload, state.setOperation)
      const tradeItemsLeft = action.payload.items.trade_items_left;
      const tradeItemsRight = action.payload.items.trade_items_right;
      let glsRet = doSetOperation(tradeItemsLeft, tradeItemsRight, state.setOperation);

  		return { ...state, loading: true,
                          tradeItemsLeft: tradeItemsLeft,
                          tradeItemsRight:  tradeItemsRight,
                          allTradeItems:  glsRet.allTradeItems,
                          tradeItems:  glsRet.allTradeItems,
                          allMasterOrientation:  glsRet.masterOrientation,
                          masterOrientation:  glsRet.masterOrientation,
							};

		case VENN_GET_LIST_ERROR:
			return { ...state,  loading: false, error: action.payload };

    case VENN_GET_LIST_WITH_FILTER:
      if ( action.payload.length > 0 ) {
        let tradeItemsFiltered = state.allTradeItems.filter( d => { if ( action.payload.find( s => s === d.record_type) ) return d; else return null; }  );
        return { ...state,
                    tradeItems: tradeItemsFiltered,
                    masterOrientation:  createMasterOrientation(tradeItemsFiltered)
                };
      }
      else {
        return { ...state, tradeItems: state.allTradeItems, masterOrientation: state.allMasterOrientation};
      }

    case VENN_CHANGE_SET_OPERATION:
      // console.log('REDUCER VENN_CHANGE_SET_OPERATION',action.payload);
      let csoRet = doSetOperation(state.tradeItemsLeft, state.tradeItemsRight, action.payload);
      // console.log(csoRet);
      return { ...state, loading: true,
                          allTradeItems:  csoRet.allTradeItems,
                          tradeItems:  csoRet.allTradeItems,
                          allMasterOrientation:  csoRet.masterOrientation,
                          masterOrientation:  csoRet.masterOrientation,
													setOperation: action.payload
              };

    /*
     * find the entry in Left or Right, change it, then reapply the set operation
     */
    case  VENN_UPDATE_ALIGNMENT:
      let selected_matched_product_name = action.payload.matchEntry.l5_name_normalized;
      let selected_match_entry = action.payload.matchEntry;

      //left or right?
      let reassignedTradeItemsLeft = state.tradeItemsLeft;
      let reassignedTradeItemsRight = state.tradeItemsRight;

      if ( state.uploadId === action.payload.id) {
        reassignedTradeItemsLeft.map( (entry, index)  => {
          if ( entry.record_type === decodeURI(action.payload.nk)) {
            entry.matched_product_name = selected_matched_product_name;
            entry.matched_product_entry = selected_match_entry;
          }
          return entry;
        });
      }
      else {
        reassignedTradeItemsRight.map( (entry, index)  => {
          if ( entry.record_type === decodeURI(action.payload.nk)) {
            entry.matched_product_name = selected_matched_product_name;
            entry.matched_product_entry = selected_match_entry;
          }
          return entry;
        });

      }
      let vuaRet = doSetOperation(reassignedTradeItemsLeft, reassignedTradeItemsRight, state.setOperation);
      return { ...state, loading: true,
                          allTradeItems:  vuaRet.allTradeItems,
                          tradeItems:  vuaRet.allTradeItems,
                          allMasterOrientation:  vuaRet.masterOrientation,
                          masterOrientation:  vuaRet.masterOrientation,
              };


		default: return { ...state };
	}
}
