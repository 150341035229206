import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { API } from "aws-amplify";
import awsConfig from '../../helpers/Aws.js'
import axios from 'axios';

import {
  SUPPLYPLAN_GET_DATA,
  SUPPLYPLAN_GET_METADATA,
  SUPPLYPLAN_SET_METADATA,
} from '../actions';

import {
  getDataSuccess,
  getDataError,
  getMetadataSuccess,
  getMetadataError,
  setMetadataSuccess,
  setMetadataError,
} from "./actions";

/*
 * Get data
 */
 const getDataRequest = async (upload_item, s3_presignedurl_list) => {
    // console.log('getDataRequest',s3_presignedurl_list)
   return await new Promise((success, fail) => {
     let ret = {
       upload_item: upload_item,  
       supply_plans: []
     }
     //now go through the list and grab the nk data
     let promises = []
     s3_presignedurl_list.forEach( s3url => {
       if ( s3url !== null) promises.push(axios.get(s3url));
     });

     axios.all(promises)
     .then(responseArr => {
       //this will be executed only when all requests are complete
       responseArr.forEach( res => {
         ret.supply_plans = ret.supply_plans.concat(res.data);
       });
       success(ret);
     });
   });
 }

function* getData({ payload }) {
  const { upload_item, s3_presignedurl_list } = payload;
  try {
    const response = yield call(getDataRequest,upload_item,s3_presignedurl_list);
    yield put(getDataSuccess(response));
  } catch (error) {
    yield put(getDataError(error));
  }
}

export function* watchGetData() {
  yield takeEvery(SUPPLYPLAN_GET_DATA, getData);
}

/*
 * Get metadata
 */
const getMetadataRequest = async (id) => {

  return await new Promise((success, fail) => {
    const url = '/supply-plan/metadata' + ( id !== undefined ? '?id='+id : '' )
// console.log('url',url)
    API.get(awsConfig.apiGateway.NAME, url)
    .then(response => {
        return response;
      }).then(data => {
        success(data);
      }); //end THEN
  })
    .then(response => response)
      .catch(error => error);
};


function* getMetadata({ payload }) {
  const id = payload;
  try {
    const response = yield call(getMetadataRequest,id);
    yield put(getMetadataSuccess(response));
  } catch (error) {
    yield put(getMetadataError(error));
  }
}

export function* watchGetMetadata() {
  yield takeEvery(SUPPLYPLAN_GET_METADATA, getMetadata);
}

/*
 * change the metadata
 */
const setMetadataRequest = async (id, delivery_date, delivery_qty) => {
  return await new Promise((success, fail) => {
    const payload =  {
      delivery_date: delivery_date,
      delivery_qty: delivery_qty,
      id: id
    }

    API.post(awsConfig.apiGateway.NAME, "/supply-plan/metadata", {
      body: payload
      }).then(data => {
          success(data);
      });
  })
    .then(response => response)
    .catch(error => error);
};

function* setMetadata({ payload }) {
// console.log('setMetadata',payload)
  const { id, delivery_date, delivery_qty } = payload;
  try {
    const response = yield call(setMetadataRequest, id, delivery_date, delivery_qty);
    yield put(setMetadataSuccess(response));
  } catch (error) {
    yield put(setMetadataError(error));
  }
}

export function* watchSetMetadata() {
  yield takeEvery(SUPPLYPLAN_SET_METADATA, setMetadata);
}



export default function* rootSaga() {
  yield all([
    fork(watchGetData),
    fork(watchGetMetadata),
    fork(watchSetMetadata),
  ]);
}
