import {
  ALIGN_COUNTRY_GET_LIST,
  ALIGN_COUNTRY_GET_LIST_SUCCESS,
  ALIGN_COUNTRY_GET_LIST_ERROR,
  ALIGN_COUNTRY_GET_LIST_WITH_FILTER,
} from '../actions';


export const getAlignCountryListWithFilter = (items) => ({
    type: ALIGN_COUNTRY_GET_LIST_WITH_FILTER,
    payload: items
});

/*
 * Get list of items to be aligned
 */
export const getAlignCountryList = (id) => ({
    type: ALIGN_COUNTRY_GET_LIST,
    payload: id
});

export const getAlignCountryListSuccess = (items) => ({
    type: ALIGN_COUNTRY_GET_LIST_SUCCESS,
    payload: items
});

export const getAlignCountryListError = (error) => ({
    type: ALIGN_COUNTRY_GET_LIST_ERROR,
    payload: error
});
