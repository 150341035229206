/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const VERIFY_USER = "VERIFY_USER";
export const VERIFY_USER_SUCCESS = "VERIFY_USER_SUCCESS";
export const VERIFY_USER_ERROR = "VERIFY_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS = "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

/* datalake UPLOAD APP */
export const UPLOAD_GET_LIST = "UPLOAD_GET_LIST";
export const UPLOAD_GET_LIST_SUCCESS = "UPLOAD_GET_LIST_SUCCESS";
export const UPLOAD_GET_LIST_ERROR = "UPLOAD_GET_LIST_ERROR";
export const UPLOAD_CHANGE_SELECTION = "UPLOAD_CHANGE_SELECTION";
export const UPLOAD_CHANGE_SELECTION_2 = "UPLOAD_CHANGE_SELECTION_2";
export const UPLOAD_SUBMIT = "UPLOAD_SUBMIT";
export const UPLOAD_SUBMIT_SUCCESS = "UPLOAD_SUBMIT_SUCCESS";
export const UPLOAD_SUBMIT_ERROR = "UPLOAD_SUBMIT_ERROR";
export const UPLOAD_DELETE = "UPLOAD_DELETE";
export const UPLOAD_DELETE_SUCCESS = "UPLOAD_DELETE_SUCCESS";
export const UPLOAD_DELETE_ERROR = "UPLOAD_DELETE_ERROR";
export const UPLOAD_SHARE_USER = "UPLOAD_SHARE_USER";
export const UPLOAD_SHARE_USER_SUCCESS = "UPLOAD_SHARE_USER_SUCCESS";
export const UPLOAD_SHARE_USER_ERROR = "UPLOAD_SHARE_USER_ERROR";
export const UPLOAD_SHARE_ORG = "UPLOAD_SHARE_ORG";
export const UPLOAD_SHARE_ORG_SUCCESS = "UPLOAD_SHARE_ORG_SUCCESS";
export const UPLOAD_SHARE_ORG_ERROR = "UPLOAD_SHARE_ORG_ERROR";

/* datalake SEARCH APP */
export const SEARCH_GET_LIST = "SEARCH_GET_LIST";
export const SEARCH_GET_LIST_SUCCESS = "SEARCH_GET_LIST_SUCCESS";
export const SEARCH_GET_LIST_ERROR = "SEARCH_GET_LIST_ERROR";
export const SEARCH_GET_LIST_WITH_FILTER = "SEARCH_GET_LIST_WITH_FILTER";
export const SEARCH_CLEAR_LIST_CANDIDATES = "SEARCH_CLEAR_LIST_CANDIDATES";
export const SEARCH_GET_LIST_CANDIDATES = "SEARCH_GET_LIST_CANDIDATES";
export const SEARCH_GET_LIST_CANDIDATES_SUCCESS = "SEARCH_GET_LIST_CANDIDATES_SUCCESS";
export const SEARCH_GET_LIST_CANDIDATES_ERROR = "SEARCH_GET_LIST_CANDIDATES_ERROR";
export const SEARCH_GET_PRODUCT_METADATA = "SEARCH_GET_PRODUCT_METADATA";
export const SEARCH_GET_PRODUCT_METADATA_SUCCESS = "SEARCH_GET_PRODUCT_METADATA_SUCCESS";
export const SEARCH_GET_PRODUCT_METADATA_ERROR = "SEARCH_GET_PRODUCT_METADATA_ERROR";

/* datalake SEARCH LOCATION APP */
export const SEARCH_LOCATION_CLEAR_LIST_CANDIDATES = "SEARCH_LOCATION_CLEAR_LIST_CANDIDATES";
export const SEARCH_LOCATION_GET_LIST_CANDIDATES = "SEARCH_LOCATION_GET_LIST_CANDIDATES";
export const SEARCH_LOCATION_GET_LIST_CANDIDATES_SUCCESS = "SEARCH_LOCATION_GET_LIST_CANDIDATES_SUCCESS";
export const SEARCH_LOCATION_GET_LIST_CANDIDATES_ERROR = "SEARCH_LOCATION_GET_LIST_CANDIDATES_ERROR";
export const SEARCH_LOCATION_GET_LIST = "SEARCH_LOCATION_GET_LIST";
export const SEARCH_LOCATION_GET_LIST_SUCCESS = "SEARCH_LOCATION_GET_LIST_SUCCESS";
export const SEARCH_LOCATION_GET_LIST_ERROR = "SEARCH_LOCATION_GET_LIST_ERROR";
export const SEARCH_LOCATION_CHANGE_COUNTRY = "SEARCH_LOCATION_CHANGE_COUNTRY";
export const SEARCH_LOCATION_CHANGE_VIEWPORT = "SEARCH_LOCATION_CHANGE_VIEWPORT";

/* datalake ALIGN APP */
export const ALIGN_GET_LIST = "ALIGN_GET_LIST";
export const ALIGN_GET_LIST_SUCCESS = "ALIGN_GET_LIST_SUCCESS";
export const ALIGN_GET_LIST_ERROR = "ALIGN_GET_LIST_ERROR";
export const ALIGN_GET_LIST_WITH_FILTER = "ALIGN_GET_LIST_WITH_FILTER";
export const ALIGN_GET_CANDIDATE_LIST = "ALIGN_GET_CANDIDATE_LIST";
export const ALIGN_GET_CANDIDATE_LIST_SUCCESS = "ALIGN_GET_CANDIDATE_LIST_SUCCESS";
export const ALIGN_GET_CANDIDATE_LIST_ERROR = "ALIGN_GET_CANDIDATE_LIST_ERROR";
export const ALIGN_SUBMIT_CANDIDATE = "ALIGN_SUBMIT_CANDIDATE";
export const ALIGN_GET_CANDIDATE_SUCCESS = "ALIGN_GET_CANDIDATE_SUCCESS";
export const ALIGN_GET_CANDIDATE_ERROR = "ALIGN_GET_CANDIDATE_ERROR";
export const ALIGN_CHANGE = "ALIGN_CHANGE";
export const ALIGN_CHANGE_SUCCESS = "ALIGN_CHANGE_SUCCESS";
export const ALIGN_CHANGE_ERROR = "ALIGN_CHANGE_ERROR";

/* datalake VENN APP */
export const VENN_GET_LIST = "VENN_GET_LIST";
export const VENN_GET_LIST_SUCCESS = "VENN_GET_LIST_SUCCESS";
export const VENN_GET_LIST_ERROR = "VENN_GET_LIST_ERROR";
export const VENN_GET_LIST_WITH_FILTER = "VENN_GET_LIST_WITH_FILTER";
export const VENN_CHANGE_SET_OPERATION = "VENN_CHANGE_SET_OPERATION";
export const VENN_UPDATE_ALIGNMENT = "VENN_UPDATE_ALIGNMENT";

/* datalake ALIGN COUNTRY APP */
export const ALIGN_COUNTRY_GET_LIST = "ALIGN_COUNTRY_GET_LIST";
export const ALIGN_COUNTRY_GET_LIST_SUCCESS = "ALIGN_COUNTRY_GET_LIST_SUCCESS";
export const ALIGN_COUNTRY_GET_LIST_ERROR = "ALIGN_COUNTRY_GET_LIST_ERROR";
export const ALIGN_COUNTRY_GET_LIST_WITH_FILTER = "ALIGN_COUNTRY_GET_LIST_WITH_FILTER";

/* datalake ALIGN LOCATION APP */
export const ALIGN_LOCATION_GET_LIST = "ALIGN_LOCATION_GET_LIST";
export const ALIGN_LOCATION_GET_LIST_SUCCESS = "ALIGN_LOCATION_GET_LIST_SUCCESS";
export const ALIGN_LOCATION_GET_LIST_ERROR = "ALIGN_LOCATION_GET_LIST_ERROR";
export const ALIGN_LOCATION_GET_CANDIDATE_LIST = "ALIGN_LOCATION_GET_CANDIDATE_LIST";
export const ALIGN_LOCATION_GET_CANDIDATE_LIST_SUCCESS = "ALIGN_LOCATION_GET_CANDIDATE_LIST_SUCCESS";
export const ALIGN_LOCATION_GET_CANDIDATE_LIST_ERROR = "ALIGN_LOCATION_GET_CANDIDATE_LIST_ERROR";
export const ALIGN_LOCATION_CHANGE = "ALIGN_LOCATION_CHANGE";
export const ALIGN_LOCATION_CHANGE_SUCCESS = "ALIGN_LOCATION_CHANGE_SUCCESS";
export const ALIGN_LOCATION_CHANGE_ERROR = "ALIGN_LOCATION_CHANGE_ERROR";

/* supplyplan APP */
export const SUPPLYPLAN_GET_DATA = "SUPPLYPLAN_GET_DATA";
export const SUPPLYPLAN_GET_DATA_SUCCESS = "SUPPLYPLAN_GET_DATA_SUCCESS";
export const SUPPLYPLAN_GET_DATA_ERROR = "SUPPLYPLAN_GET_DATA_ERROR";
export const SUPPLYPLAN_CHANGE_PRODUCT = "SUPPLYPLAN_CHANGE_PRODUCT";
export const SUPPLYPLAN_CHANGE_COUNTRY = "SUPPLYPLAN_CHANGE_COUNTRY";
export const SUPPLYPLAN_CHANGE_UPLOAD = "SUPPLYPLAN_CHANGE_UPLOAD";
export const SUPPLYPLAN_GET_METADATA = "SUPPLYPLAN_GET_METADATA";
export const SUPPLYPLAN_GET_METADATA_SUCCESS = "SUPPLYPLAN_GET_METADATA_SUCCESS";
export const SUPPLYPLAN_GET_METADATA_ERROR = "SUPPLYPLAN_GET_METADATA_ERROR";
export const SUPPLYPLAN_SET_METADATA = "SUPPLYPLAN_SET_METADATA";
export const SUPPLYPLAN_SET_METADATA_SUCCESS = "SUPPLYPLAN_SET_METADATA_SUCCESS";
export const SUPPLYPLAN_SET_METADATA_ERROR = "SUPPLYPLAN_SET_METADATA_ERROR";


export * from "./menu/actions";
export * from "./settings/actions";
export * from "./auth/actions";
export * from "./datalakeUpload/actions";
export * from "./datalakeSearch/actions";
export * from "./datalakeSearchLocation/actions";
export * from "./datalakeAlign/actions";
export * from "./datalakeAlignCountry/actions";
export * from "./datalakeAlignLocation/actions";
export * from "./datalakeVenn/actions";
export * from "./supplyplan/actions";
