// URL: 'https://fpmly4w10l.execute-api.us-east-1.amazonaws.com/Prod'

// AWS Auth configuration
const awsConfig = {
  s3: {
    REGION: "us-east-1",
    BUCKET: 'mosaic-data-075886804791-us-east-1'
  },
  apiGateway: {
    NAME: "mosaic",
    REGION:  "us-east-1",
    URL: 'https://fpmly4w10l.execute-api.us-east-1.amazonaws.com/Prod'
  },
  cognito: {
    REGION:  "us-east-1",
    USER_POOL_ID: 'us-east-1_awFCix3P3',
    APP_CLIENT_ID: '5kkeaebjo8njlto14daou5o3o2',
    IDENTITY_POOL_ID: "us-east-1:d8b9297c-8670-41a8-a3f3-23f7defdf1c4"
  },
  MAX_ATTACHMENT_SIZE: 5000000,
};

export default awsConfig;
